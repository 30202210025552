export const CZ = {
  _1_CONFIRMATION_REQUIRED: 'Požadováno 1 potvrzení',
  _2_CONFIRMATIONS_REQUIRED: 'Požadována 2 potvrzení',
  ACKNOWLEDGE_DELETION_REQUEST: 'Potvrdit smazání',
  ACKNOWLEDGE_DELETION_REQUEST_FAILED: 'Potvrzení smazání selhalo.',
  ACKNOWLEDGE_DELETION_REQUEST_TEXT: 'Opravdu chcete potvrdit požadavek o smazání',
  ACTION_FAILED: 'Akce se nezdařila!',
  ACTIVE: 'Aktivní',
  ACTIVITY: 'Aktivita',
  ADMIN: 'Správce',
  ADMINISTRATION: 'Administrace',
  AGENT_IDENTIFIER: 'Identifikátor agenta',
  AGENT_IDENTIFIER_TYPE: 'Typ identifikátoru agenta',
  AGENT_NAME: 'Jméno agenta',
  AGGREGATED_EXTRACTED_TECHNICAL_METADATA: 'Technická metadata agregovaná přebíraná',
  AGGREGATED_TECHNICAL_METADATA_GENERATED_BY_ARCLIB:
    'Technická metadata agregovaná generovaná ARCLib',
  AIP: 'AIP',
  AIP_CAN_NOT_BE_DOWNLOADED: 'AIP nelze stáhnout.',
  AIP_DELETE: 'Smazat AIP',
  AIP_DELETE_TEXT: 'Opravdu chcete smazat AIP',
  AIP_DELETE_TOOLTIP:
    'Vytvoří požadavek na smazání, který musí potvrdit další dva uživatelé. Smazaný balíček je smazán z uložiště natrvalo.',
  AIP_EDITOR: 'Editace AIP',
  AIP_FORGET: 'Zapomenout AIP',
  AIP_ID: 'AIP ID',
  AIP_IDS: 'Identifikátory AIP',
  AIP_REGISTER_UPDATE_FAILED: 'Zamknutí editace se nezdařilo!',
  AIP_REMOVE: 'Odstranit AIP',
  AIP_REMOVE_TEXT: 'Opravdu chcete odstranit AIP',
  AIP_REMOVE_TOOLTIP:
    'Provede logické odstranění balíčku. Logicky odstraněný balíček je přístupný pouze správcům. Správci ho mohou znovu obnovit.',
  AIP_RENEW: 'Obnovit AIP',
  AIP_RENEW_TEXT: 'Opravdu chcete obnovit AIP',
  AIP_SEARCH: 'Vyhledávání AIP',
  AIP_STATE: 'Stav AIP',
  AIP_STATE_IN_DATABASE: 'Stav AIP v databázi',
  ALIASES: 'Aliasy',
  ALL: 'Vše',
  ALL_DEBUG_VERSIONS_OF_THIS_AUTHORIAL_PACKAGE_WILL_BE_DELETED:
    'Všechny ladicí verze tohoto autorského balíčku budou smazány.',
  ANALYST: 'Analitik',
  ANY_ATTRIBUTE_NAME: 'Název kteréhokoliv atributu',
  ANY_ATTRIBUTE_VALUE: 'Hodnota kteréhokoliv atributu',
  ARC_EVENT_AGENT_ID: 'ID agenta ARC události',
  ARC_EVENT_COUNT: 'Počet ARC událostí',
  ARC_EVENT_DATE: 'Datum ARC události',
  ARC_EVENT_TYPE: 'Typ ARC události',
  ARCLIB_XML: 'ARCLib XML',
  ARCLIB_XML_DS: 'ARCLib XML index report',
  ARCLIB_XML_DS_TOOLTIP:
    'Report vychází z indexu ARCLib XML - datovým zdrojem je index. V opačném případě je datovým zdrojem ARCLib databáze.',
  ARCHIVAL_STORAGE: 'Archivační uložiště',
  ARCHIVAL_STORAGE_ADMINISTRATION: 'Správa archivačního uložiště',
  ARCHIVAL_STORAGE_IS_READ_ONLY_AT_THE_MOMENT: 'Archivační uložiště je momentálně pouze pro čtení.',
  ARCHIVIST: 'Archivář',
  ASSIGNEE: 'Nabyvatel',
  ATTACH_AGAIN: 'Znovu zapojit',
  ATTACH_NEW_STORAGE: 'Připojit nové úložiště',
  ATTACH_NEW_STORAGE_FAILED: 'Připojení nového uložiště selhalo.',
  AUTHORIAL_ID: 'Autorské ID',
  AUTO: 'Auto',
  AVAILABILITY_OF_STORAGES_LAST_CHECKED: 'Dostupnost uložišť naposledy kontrolována:',
  BATCH: 'Dávka',
  BATCH_FAILED_TO_RESUME: 'Dávka nemohla být obnovena kvůli probíhajícím ingest workflows.',
  BATCH_HAS_SUCCESSFULLY_RESUMED: 'Dávka byla úspěšně obnovena.',
  BATCH_PROCESS_ONE_LOADER_TEXT: 'Počkejte na dokončení nahrávání SIP balíku.',
  BPM_TASK_ID: 'ID BPM úlohy',
  BPMN_DEFINITION: 'BPMN definice',
  AIP_BULK_DELETIONS: 'Hromadné mazání',
  AIP_BULK_DELETION_NEW: 'Nové hromadné mazání',
  AIP_BULK_DELETION_NEW_NOTE: 'Identifikátory v seznamu musí být odděleny čárkami.',
  BYTE_ARRAY: 'Byte array',
  CANCEL: 'Zrušit',
  CANCELED: 'Zrušeno',
  CHECK_NOW: 'Kontrolovat nyní',
  CHECKSUM_TYPE: 'Typ kontrolního součtu',
  CLEAN_STORAGE: 'Pročistit úložiště',
  CLEAN_STORAGE_ALL: 'Pročistit úložiště - i rozpracované objekty',
  CLEAN_STORAGE_ALL_TEXT: 'Opravdu chcete pročistit uložiště, včetně rozpracovaných objektů?',
  CLEAN_STORAGE_FAILED: 'Pročištění uložiště bylo neúspěšné!',
  CLEAN_STORAGE_SUCCESSFULL: 'Pročištění uložiště bylo úspěšné!',
  CLEAN_STORAGE_TEXT: 'Opravdu chcete pročistit uložiště?',
  CLOSE: 'Zavřít',
  CODE: 'Kód',
  COLLAPSE_ALL: 'Vše srolovat',
  COLUMN_SETTINGS: 'Nastavení sloupců',
  COMPUTED_WORKFLOW_CONFIGURATION: 'Vypočítaná konfigurace workflow',
  CONFIGURATION_FILE: 'Konfigurační soubor',
  CONFIGURATION_FILE_LOCAL: 'Lokální konfigurační soubor',
  CONFIRMED: 'Potvrzeno',
  CONSISTENCY: 'Konzistence',
  CONSISTENCY_OF_SIP: 'Konzistence SIP',
  CONTAINS: 'Obsahuje',
  CONTENT_CONSISTENCY: 'Konzistence obsahu',
  CREATED: 'Vytvořeno',
  CREATING_APPLICATION_NAME: 'Jméno vytvářející aplikace',
  CREATING_APPLICATION_VERSION: 'Verze vytvářející aplikace',
  CREATING_APPLICATIONS: 'Vytvářející aplikace',
  CREATOR: 'Tvůrce',
  CRON_EXPRESSION: 'CRON výraz',
  DATABASE_CHECKSUM: 'Kontrolní součet v databázi',
  DATE_CREATED: 'Datum pořízení',
  DATE_CREATED_BY_APPLICATION: 'Datum vytvoření aplikací',
  DATE_TIME_PICKER_PLACEHOLDER: 'DD.MM.RRRR HH:mm',
  DEBUGGING_MODE_ACTIVE: 'Režim ladění je aktivní',
  DELETE: 'Smazat',
  DELETED_ITEM: 'Smazaná položka',
  DELETION_ACKNOWLEDGE: 'Potvrzování smazání',
  DELETE_FAILED: 'Smazání se nezdařilo.',
  DELETED: 'Smazán',
  DELETION_REQUEST_ALREADY_CREATED: 'Požadavek o smazání AIP byl již vytvořen.',
  DELETION_REQUEST_SUCCESSFULLY_CREATED: 'Požadavek o smazání úspěšně vytvořený.',
  DELETION_REQUESTS: 'Požadavky o smazání',
  DESCRIPTION: 'Popis',
  DESCRIPTIVE_METADATA: 'Popisná metadata',
  DETACH: 'Odpojit',
  DETACHED: 'Odpojeno',
  DETACHED_BY_ADMIN: 'Odpojeno administrátorem',
  DETACHED_BY_ERROR: 'Odpojeno chybou',
  DEVELOPER_ID: 'ID vývojáře',
  DEVELOPER_NAME: 'Jméno',
  DEVELOPER_COUMPOUND_NAME: 'Složené jméno',
  DEVELOPERS: 'Vývojáři',
  DEVICE_FILE_COUNT: 'Počet souborů zařízení',
  DISACKNOWLEDGE_DELETION_REQUEST: 'Zamítnout smazání',
  DISACKNOWLEDGE_DELETION_REQUEST_FAILED: 'Zamítnutí smazání selhalo.',
  DISACKNOWLEDGE_DELETION_REQUEST_TEXT: 'Opravdu chcete zamítnout požadavek o smazání',
  DOCUMENTATION: 'Dokumentace',
  DONE: 'Hotovo',
  DOWNLOAD: 'Stáhnout',
  DOWNLOAD_AIP: 'Stáhnout AIP',
  DOWNLOAD_XML: 'Stáhnout XML',
  DROP_FILE_OR_CLICK_TO_SELECT_FILE:
    'Přetáhněte sem soubor nebo klikněte pro zvolení souboru pro nahrání.',
  DROP_FILES_OR_CLICK_TO_SELECT_FILES:
    'Přetáhněte sem soubory nebo klikněte pro zvolení souborů pro nahrání.',
  DUBLIN_CORE: 'Dublin core',
  EDIT: 'Upravit',
  EDITABLE: 'Editovatelný',
  EDITED: 'Upraveno',
  EDITATION: 'Editace',
  EDITOR: 'Editor',
  EMAIL: 'Email',
  ENDWITH: 'Končí s',
  ENTER_CURRENT_OR_FUTURE_DATE: 'Zadejte aktuální nebo budoucí datum.',
  ENTER_LOCALHOST_OR_IPv4: 'Zadejte `localhost` nebo IPv4 adresu.',
  ENTER_VALID_CRON_FORMAT: 'Zadejte řetězec v CRON formátu.',
  ENTER_VALID_DATE_TIME_FORMAT: 'Zadejte platný formát data a času.',
  ENTER_VALID_EMAIL_ADDRESS: 'Zadejte platnou email adresu.',
  ENTER_VALID_JSON: 'Zadejte platný JSON.',
  ENTER_VALID_NUMBER: 'Zadejte platnou číselnou hodnotu.',
  ENTER_VALID_NUMBER_BIGGER_OR_EQUAL_1: 'Zadejte platnou číselnou hodnotu větší než nebo rovno 1.',
  ENTITY_WITH_THIS_NAME_ALREADY_EXISTS: 'Entita s tímto jménem již existuje.',
  EQ: 'Rovná se',
  EVENT: 'Událost',
  EVENT_AGENT_ID: 'ID agenta události',
  EVENT_COUNT: 'Počet událostí',
  EVENT_DATE: 'Datum události',
  EVENT_DESCRIPTION: 'Popis události',
  EVENT_DETAIL: 'Detail události',
  EVENT_ID: 'ID události',
  EVENT_IDENTIFIER: 'Identifikátor události',
  EVENT_IDENTIFIER_TYPE: 'Typ identifikátoru události',
  EVENT_INGESTION: 'Přijetí události',
  EVENT_OUTCOME: 'Výsledek události',
  EVENT_TYPE: 'Typ události',
  EVENT_VALIDATION: 'Událost validace',
  EVENTS: 'Události',
  EXCEPTION_MESSAGE: 'Zpráva chyby',
  EXCEPTION_STACKTRACE: 'StackTrace výjimky',
  EXPORT_AREA_PATH: 'Cesta do exportní lokace',
  EXPORT_CURRENT_PAGE: 'Exportovat aktuální stránku',
  EXPORT_FAILED: 'Export se nezdařil!',
  EXPORT_FORMAT_DEFINITION: 'Exportovat definici formátu',
  EXPORT_FORMAT_DEFINITIONS: 'Exportovat definice formátu',
  EXPORT_JUST_XML: 'Exportovat pouze XML',
  EXPORT_LOCATION_PATH: 'Cesta do exportní lokace',
  EXPORT_ROUTINE_DELETE: 'Smazat exportní rutinu',
  EXPORT_ROUTINE_DELETE_TEXT: 'Opravdu chcete smazat exportní rutinu?',
  EXPORT_SEARCH_RESULTS: 'Exportovat výsledky vyhledávání',
  EXPORT_TIME: 'Čas exportu',
  EXPORT_TYPE: 'Typ exportu',
  EXPORT_WHOLE_AIP_INCLUDING_ALL_XML_VERSIONS:
    'Exportovat kompletní obsah AIP včetně všech verzí XML',
  EXPORT_WHOLE_AIP_INCLUDING_LATEST_XML_VERSION:
    'Exportovat kompletní obsah AIP včetně poslední verze XML',
  EXTERNAL_ID: 'Externí ID',
  FAILED: 'Selhalo',
  FAILED_AND_ALL_CURRENTLY_PROCESSING_STUCKED_OBJECTS_WILL_BE_CLEANED:
    'Vyčistí pozůstatky objektů při jejichž zpracování došlo k chybě a také všechny objekty které jsou momentálně zpracovávány. Navíc je pročištěno pracovní prostředí Archivačního Úložiště (dočasné soubory apod.).',
  FAILED_TO_READ_FILE: 'Nezdařilo se přečíst soubor.',
  FILE_COUNT: 'Počet souborů',
  FILE_FORMAT: 'Formát souboru',
  FILE_SUCCESSFULLY_UPLOADED: 'Soubor úspěšně nahrán.',
  FILES: 'Soubory',
  FILTER_BY_USER: 'Filtrovat dle uživatele',
  FIRST_NAME: 'Křestní jméno',
  FORGET: 'Zapomenout',
  FORMAT: 'Formát',
  FORMAT_CLASSIFICATIONS: 'Klasifikace formátu',
  FORMAT_DEFINITION: 'Definice formátu',
  FORMAT_DESCRIPTION: 'Popis formátu',
  FORMAT_FAMILIES: 'Rodiny formátu',
  FORMAT_ID: 'ID formátu',
  FORMAT_LIBRARY_UPDATE_FAILED: 'Aktualizace formátové knihovny selhala.',
  FORMAT_LIBRARY_UPDATE_STARTED: 'Aktualizace formátové knihovny byla zahájena.',
  FORMAT_NAME: 'Název formátu',
  FORMAT_NOTE: 'Poznámka formátu',
  FORMAT_OCCURRENCES: 'Výskyty formátu',
  FORMAT_REGISTRY_KEY: 'Klíč ve formátovém registru',
  FORMAT_REGISTRY_NAME: 'Jméno formátového registru',
  FORMAT_RELATION_TYPE: 'Typ vztahu formátu',
  FORMAT_REVISION: 'Revize formátu',
  FORMAT_VERSION: 'Verze formátu',
  FORMATS: 'Formáty',
  FROM: 'od',
  FULL_NAME: 'Jméno',
  GENERAL_CONTENT_SEARCH: 'Obecné hledání v celém XML',
  GENERIC_DUBLIN_CORE: 'Generický Dublin Core',
  GT: 'Je větší',
  GTE: 'Je větší nebo rovno',
  HASH_TYPE: 'Hash typ',
  HASH_VALUE: 'Hodnota hash',
  HEADER: 'Hlavička',
  HIGHER_PRIORITY_STORAGE_IS_PREFERRED_DURING_READ_OPERATION:
    'Úložiště s vyšší prioritou jsou preferovány při operaci čtení.',
  HOME_PAGE: 'Domovská stránka',
  HOST: 'Host',
  ID: 'ID',
  ID_OF_PREVIOUS_SIP_VERSION: 'ID předchozí verze SIP',
  ID_OF_PREVIOUS_XML_VERSION: 'ID předchozí verze XML',
  IDENTIFICATION_TOOL: 'Identifikační nástroj',
  IDENTIFIED_FORMATS: 'Identifikované formáty',
  IDENTIFIER: 'Identifikátor',
  IDENTIFIER_DELETE: 'Smazat identifikátor',
  IDENTIFIER_DELETE_TEXT: 'Opravdu chcete smazat identifikátor',
  IDENTIFIER_NEW: 'Nový identifikátor',
  IDENTIFIER_TYPE: 'Typ identifikátoru',
  IDENTIFIERS: 'Identifikátory',
  IMAGE_CAPTURE_METADATA: 'Metadata z pořízení snímku',
  IMAGE_PRODUCER: 'Pořizovatel snímku',
  IMPORT_FORMAT_DEFINITION: 'Importovat definici formátu',
  IMPORT_FORMAT_DEFINITIONS: 'Importovat definice formátu',
  IMPORT_FORMAT_DEFINITION_FROM_BYTE_ARRAY:
    'Importovat definici formátu ze souboru typu byte array',
  IMPORT_FORMAT_DEFINITIONS_FROM_BYTE_ARRAY:
    'Importovat definice formátu ze souboru typu byte array',
  IMPORT_FORMAT_DEFINITION_FROM_JSON: 'Importovat definici formátu ze souboru typu JSON',
  IMPORT_FORMAT_DEFINITIONS_FROM_JSON: 'Importovat definice formátu ze souboru typu JSON',
  IN_ADDITION_THE_AVAILABILITY_IS_CHECKED_WITH_EVERY_WRITE:
    'Dostupnost je navíc kontrolována při každém zápisu.',
  INCIDENT: 'Incident',
  INCIDENT_DETAIL: 'Detail incidentu',
  INCIDENTS: 'Incidenty',
  INCIDENTS_CANCEL_FAILED: 'Nepodařilo se zrušit zvolené incidenty.',
  INCIDENTS_SOLVE_FAILED: 'Nepodařilo se vyřešit zvolené incidenty.',
  INCORRECT_USERNAME_OR_PASSWORD: 'Nesprávné přihlašovací údaje.',
  INDEX: 'Index',
  INFORMATION_UPDATED: 'Informace aktualizována',
  INGEST: 'Ingest',
  INGEST_BATCH: 'Ingest dávka',
  INGEST_BATCHES: 'Ingest dávky',
  INGEST_ROUTINE: 'Ingest rutina',
  INGEST_ROUTINE_DELETE: 'Smazat ingest rutinu',
  INGEST_ROUTINE_DELETE_TEXT: 'Opravdu chcete smazat ingest rutinu',
  INGEST_ROUTINE_NEW: 'Nová ingest rutina',
  INGEST_ROUTINE_NEW_FAILED: 'Nepodařilo se vytvořit nový ingest routine.',
  INGEST_ROUTINE_NEW_TRANSFER_AREA_PATH_NOTE:
    'Cesta do překladiště označuje vlastní cestu v překladišti dodavatele, pokud je prázdná, použije se kořenová složka překladiště dodavatelů.',
  INGEST_ROUTINES: 'Ingest rutiny',
  INGEST_WORKFLOW: 'Ingest workflow',
  INGEST_WORKFLOW_FAILURE_TYPE: 'Typ poruchy ingest workflow',
  INGEST_WORKFLOW_ID: 'ID ingest workflow',
  INGEST_WORKFLOWS: 'Ingest workflows',
  INGESTION_EVENT: 'Událost ingestu',
  INITIAL_WORKFLOW_CONFIGURATION: 'Počáteční konfigurace workflow',
  INSTITUTION: 'Instituce',
  INTERNAL: 'Interní',
  INTERNAL_INFORMATION_FILLED: 'Interní informace vyplněna',
  INTERNAL_VERSION_NUMBER: 'Interní číslo verze',
  ISSUE: 'Chyba',
  ISSUE_DEFINITIONS: 'Definice chyb',
  ISSUE_DELETE: 'Smazat chybu',
  ISSUE_DELETE_TEXT: 'Opravdu chcete smazat chybu',
  ISSUE_DICTIONARY: 'Číselník chyb',
  ISSUE_NEW: 'Nová chyba',
  ISSUE_NEW_FAILED: 'Nepodařilo se vytvořit novou chybu.',
  ISSUE_TYPE: 'Typ chyby',
  ISSUES: 'Chyby',
  ISSUES_NOT_AVAILABLE: 'Žádná chyby nejsou k dispozici.',
  JOB: 'Job',
  JSON: 'JSON',
  KEY: 'Klíč',
  LABEL: 'Označení',
  LAST_NAME: 'Příjmení',
  LATEST_VERSION: 'Poslední verze',
  LATEST_DATA_VERSION: 'Poslední verze dat',
  LDAPDN: 'LdapDN',
  LICENSE_INFORMATION: 'Informace o licenci',
  LOADING: 'Načítání...',
  LOCAL_DEFINITION: 'Lokální definice',
  LOCKED_AT: 'Zamčeno v',
  LOCKED_BY_USER: 'Zamčeno uživatelem',
  LOGICAL_STORAGE_ADMINISTRATION: 'Správa logických uložišť',
  LT: 'Je menší',
  LTE: 'Je menší nebo rovno',
  MESSAGE: 'Zpráva',
  METADATA_CONSISTENCY: 'Konzistence metadat',
  MIN_STORAGE_COUNT: 'Minimální počet úložišť',
  MODE: 'Režim',
  NAME: 'Název',
  NATIONAL_FORMAT_GUARANTOR: 'Národní garant formátu',
  NEW: 'Nový',
  NEW_FAILED: 'Nepodařilo se vytvořit novou položku.',
  NEQ: 'Nerovná se',
  NEW_SEARCH_BASED_ON_QUERY: 'Nové vyhledávání založené na dotazu',
  NO: 'Ne',
  NO_ITEMS: 'Žádné položky',
  NOTE: 'Poznámka',
  NOTIFICATION: 'Notifikace',
  NOTIFICATION_DELETE: 'Smazat notifikaci',
  NOTIFICATION_DELETE_TEXT: 'Opravdu chcete smazat notifikaci?',
  NOTIFICATION_NEW: 'Nová notifikace',
  NOTIFICATION_NEW_FAILED: 'Nepodařilo se vytvořit novou notifikaci.',
  NOTIFICATIONS: 'Notifikace',
  NUMBER: 'Číslo',
  OCCURRENCES: 'Výskyty',
  OK: 'OK',
  ON_STORAGE: 'na uložišti',
  ONLY_FAILED_OBJECTS_WILL_BE_CLEANED:
    'Vyčistí pozůstatky objektů při jejichž zpracování došlo k chybě.',
  OPENS_PAGE_WITH_CRON_EXPRESSION_INFORMATION: 'Otevře stránku s informacemi o CRON výrazu.',
  OPENS_PAGE_WITH_GLOB_PATTERN_INFORMATION: 'Otevře stránku s informacemi o glob výrazu.',
  OPERATION: 'Operace',
  ORGANISATION_NAME: 'Název organizace',
  PAGINATION_COUNT_DIVIDER: 'z',
  PARAMS: 'Parametry',
  PASSWORD: 'Heslo',
  PASSWORD_NEW: 'Nové heslo',
  PATH_TO_XML: 'Cesta k souboru XML s autorským ID ve formě regulárního výrazu (regex)',
  PENDING_INCIDENTS: 'Čekající incidenty',
  PERMISSION: 'Oprávnění',
  PERMISSIONS: 'Oprávnění',
  PERSISTED: 'Perzistentní',
  PLANNED_NOTIFICATIONS_TO_ADMINISTRATORS_ABOUT_FORMAT_POLITICS_REVISIONS:
    'Plánované notifikace administrátorům ohledně revizí formatových politik',
  PORT: 'Port',
  PREFERRED: 'Preferováno',
  PRESERVATION_LEVEL_VALUE: 'Úroveň ochrany',
  PRESERVATION_PLAN_DESCRIPTION: 'Popis plánování dlouhodobé ochrany',
  PRESERVATION_PLAN_FILE: 'Soubor plánování dlouhodobé ochrany',
  PRESERVATION_PLANNING: 'Plánování dlouhodobé ochrany',
  RESET_TABLE: 'Resetovat tabulku',
  PRIORITY: 'Priorita',
  PRIMARY_STORAGE: 'Primární úložiště',
  PRIMARY_STORAGE_SWITCH: 'Změnit primární úložiště',
  PROCESS_VARIABLE: 'Procesní proměnná',
  PROCESS_VARIABLES: 'Procesní proměnné',
  PROCESSED: 'Zpracováno',
  PROCESSED_WITH_FAILURES: 'Zpracováno s chybami',
  PROCESSING: 'Zpracovává se',
  PROCESSING_STATE: 'Stav zpracování',
  PRODUCER: 'Dodavatel',
  PRODUCER_ID: 'ID dodavatele',
  PRODUCER_NAME: 'Jméno dodavatele',
  PRODUCERS: 'Dodavatelé',
  PRODUCER_DELETE: 'Smazat dodavatele',
  PRODUCER_DELETE_TEXT: 'Opravdu chcete smazat dodavatele',
  PRODUCER_NEW: 'Nový dodavatel',
  PRODUCER_NEW_FAILED: 'Nepodařilo se vytvořit nového výrobce.',
  PRODUCER_PROFILE: 'Profil dodavatele',
  PRODUCER_PROFILE_DELETE: 'Smazat profil dodavatele',
  PRODUCER_PROFILE_DELETE_TEXT: 'Opravdu chcete smazat profil dodavatele',
  PRODUCER_PROFILE_EXTERNAL_ID: 'Externí ID profilu dodavatele',
  PRODUCER_PROFILE_ID_AUTHORIAL_ID: 'ID profilu dodavatele - Autorské ID',
  PRODUCER_PROFILE_HAS_DEBUG_MODE_ACTIVE:
    'Profil dodavatele má nastaven debug mód. V tomtu módu budou data uložena na disk ARCLib serveru místo do Archival Storage.',
  PRODUCER_PROFILE_NAME: 'Název profilu dodavatele',
  PRODUCER_PROFILE_NEW: 'Nový profil dodavatele',
  PRODUCER_PROFILE_NEW_FAILED: 'Nepodařilo se vytvořit nový producer profile.',
  PRODUCER_PROFILES: 'Profily dodavatelů',
  PROFILE: 'Profil',
  PROFILE_UPDATED: 'Profil byl úspěšně aktualizován.',
  PROVENANCE_AND_CHECKS_DURING_INGEST: 'Provenance a kontroly při ingestu',
  PROVENANCE_DURING_REVALIDATION_WITH_NEW_PROFILE_OR_REPEATED_IDENTIFICATION_OF_FORMATS_IN_SIP:
    'Provenance při revalidaci novým profilem nebo opakované identifikaci formátů v SIP',
  PROVENANCE_DURING_UPDATE_OF_AIP_METADATA: 'Provenance při aktualizaci AIP metadata',
  PROVENANCE_EXTRACTED_FROM_SIP: 'Provenance přebrané ze SIP',
  PUID: 'PUID',
  QUERY_NAME: 'Název dotazu',
  R_W: 'R/W',
  READ_ONLY: 'Pouze pro čtení',
  REACHABLE: 'Dosažitelné',
  REACHABILITIY_CHECK_INTERVAL_MINUTES: 'Interval kontroly dosažitelnosti (minuty)',
  REACHABILITY_OF_STORAGE: 'Dostupnost úložiště',
  REASON: 'Důvod',
  RECONFIGURABLE: 'Rekonfigurovatelné',
  REINDEX_ARCLIB_XML: 'Reindexovat ARCLib XML',
  REINDEX_ARCLIB_XML_TEXT:
    'Opravdu chcete spustit kompletní reindex všech ARCLib XML uložených v systému? Jedná se o časově náročnou operaci - všechna XML musí být vytažena z Archival Storage.',
  REINDEX_CORE: 'Reindexovat jádro',
  REINDEX_FORMAT: 'Reindexovat formát',
  REINDEX_STARTED: 'Reindex zahájen',
  REINGEST: 'Reingest',
  REINGEST_COUNT_TRANSFER_AREA: 'Spočítat balíčky v překladišti',
  REINGEST_DB_STARTED: 'Reingest zahájen',
  REINGEST_DB_TOTAL: 'Celkový počet balíčků',
  REINGEST_DB_EXPORTED: 'Počet exportovaných',
  REINGEST_INITIATE: 'Zahájit nový Reingest',
  REINGEST_INIT_WARNING: 'Opravdu chcete spustit kompletní reingest archivu? Jedná se o časově velmi náročnou operaci, při níž jsou plněny disky překladiště a workspace. Zároveň při reingestu nelze verzovat balíčky: ingesty ústící ve verzování jsou zamítnuty.',
  REINGEST_TERMINATE: 'Ukončit',
  REINGEST_TERMINATION_WARNING: 'Opravdu chcete ukončit reingest? Doporučujeme nejprve zkontrolovat počet balíčků v překladišti.',
  REINGEST_STOP_JOB: 'Pozastavit tvorbu dávek',
  REINGEST_RESUME_JOB: 'Pokračovat v tvorbě dávek',
  REINGEST_STATE_INITIATED: 'Zahájen',
  REINGEST_STATE_INIT_FAILED: 'Zahájení selhalo',
  REINGEST_STATE_JOB_STOPPED: 'Dávkování pozastaveno',
  REINGEST_STATE_JOB_RUNNING: 'Dávkování běží',
  REINGEST_STATE_JOB_FINISHED: 'Dávkování dokončeno',
  REJECTED: 'Odmítnuto',
  RELATED_ENTITIES: 'Související entity',
  RELATED_ERROR: 'Související chyba',
  RELATED_ERROR_DELETE: 'Smazat související chybu',
  RELATED_ERROR_DELETE_TEXT: 'Opravdu chcete smazat související chybu',
  RELATED_ERROR_NEW: 'Nová související chyba',
  RELATED_ERRORS: 'Související chyby',
  RELATED_FORMATS: 'Související formáty',
  RELATED_ISSUES: 'Související chyby',
  RELATED_RISKS: 'Související rizika',
  RELATIONSHIP_TYPE: 'Typ vztahu',
  RELEASE_DATE: 'Datum vydání',
  REMOVE: 'Odstranit',
  REMOVE_FAILED: 'Odstranění se nezdařilo.',
  REMOVE_SUCCESSFULL: 'Odstranění bylo úspěšně.',
  RENEW: 'Obnovit',
  RENEW_SUCCESSFULL: 'Obnovení bylo úspěšně.',
  REQUESTED: 'Požadováno',
  REQUESTER: 'Žadatel',
  REQUIRED: 'Povinné',
  REPORT: 'Report',
  REPORT_DELETE: 'Smazat report',
  REPORT_DELETE_TEXT: 'Opravdu chcete smazat report',
  REPORT_GENERATE: 'Vygenerovat report',
  REPORT_GENERATE_FAILED: 'Vygenerování reportu se nezdařilo.',
  REPORT_NEW: 'Nový report',
  REPORTS: 'Reporty',
  RESET: 'Resetovat',
  RESPONSIBLE_PERSON: 'Zodpovědná osoba',
  RESPONSIBLE_PERSON_NAME: 'Jméno zodpovědné osoby',
  RESUME: 'Pokračovat',
  REVERT: 'Vrátit zpět',
  REVERT_DELETION_REQUEST: 'Vrátit zpět požadavek o smazání',
  REVERT_DELETION_REQUEST_TEXT: 'Opravdu chcete vrátit požadavek o smazání',
  REVERTED: 'Vráceno',
  RISK: 'Riziko',
  RISK_DELETE: 'Smazat riziko',
  RISK_DELETE_TEXT: 'Opravdu chcete smazat riziko',
  RISK_NEW: 'Nové riziko',
  RISK_NEW_FAILED: 'Nepodařilo se vytvořit nové riziko.',
  RISKS: 'Rizika',
  RISKS_NOT_AVAILABLE: 'Žádná rizika nejsou k dispozici.',
  ROLE: 'Role',
  ROLE_DELETE: 'Smazat roli',
  ROLE_DELETE_TEXT: 'Opravdu chcete smazat roli?',
  ROLE_NEW: 'Nová role',
  ROLES: 'Role',
  ROLES_NOT_AVAILABLE: 'Žádné role nejsou k dispozici.',
  ROOT: 'Root',
  ROUTINE_AUTO_FLAG_CANT_BE_CHANGED_TOOLTIP: 'Příznak je editovatelný pouze při zakládání rutiny.',
  SAVE: 'Uložit',
  SAVE_AND_CLOSE: 'Uložit a zavřít',
  SAVE_FAILED: 'Uložení se nezdařilo!',
  SAVE_INTERNAL_INFORMATION: 'Uložit interní informaci',
  SAVE_SUCCESSFULL: 'Úspěšně uloženo.',
  SAVE_UPDATE_FAILED: 'Nepodařilo se uložit provedené změny.',
  SCANNER_MODEL_SERIAL_NO: 'Sériové číslo modelu skeneru',
  SEARCH_QUERIES: 'Vyhledávací dotazy',
  SEARCH_QUERY_DELETE: 'Smazat vyhledávací dotaz',
  SEARCH_QUERY_DELETE_TEXT: 'Opravdu chcete smazat vyhledávací dotaz?',
  SEARCH_QUERY_RESULTS: 'Výsledky vyhledávacího dotazu',
  SEND: 'Odeslat',
  SET_PASSWORD: 'Změnit/Nastavit heslo',
  SHOW_SEARCH_RESULTS: 'Zobrazit výsledky vyhledávání',
  SIGN_IN: 'Přihlásit',
  SIGN_OUT: 'Odhlásit',
  SIP_CONTENT: 'Obsah SIP',
  SIP_ID: 'SIP ID',
  SIP_CHECKSUM_IN_DATABASE: 'Kontrolní součet SIP v databázi',
  SIP_CHECKSUM_ON_STORAGE: 'Kontrolní součet SIP na uložišti',
  SIP_METADATA_PATH:
    'Cesta k hlavnímu souboru metadat SIP (např. METS.xml) ve tvaru regulárního výrazu (regex)',
  SIP_PROCESS_START_FAILED: 'Start zpracování SIP selhal.',
  SIP_PROCESS_START_SUCCESSFULL: 'Zpracování SIP úspěšně zahájeno.',
  SIP_PROFILE: 'SIP profil',
  SIP_PROFILE_DELETE: 'Smazat SIP profil',
  SIP_PROFILE_DELETE_TEXT: 'Opravdu chcete smazat SIP profil',
  SIP_PROFILE_EXTERNAL_ID: 'Externí ID SIP profilu',
  SIP_PROFILE_NEW: 'Nový SIP profil',
  SIP_PROFILE_NEW_FAILED: 'Nepodařilo se vytvořit nový SIP profil.',
  SIP_PROFILES: 'SIP profily',
  SIP_VERSION: 'Verze SIP',
  SIP_VERSION_NUMBER: 'Číslo verze SIP',
  SIZE: 'Velikost',
  DELETE_IF_NEWER_VERSIONS_DELETED: 'Smazat i pokud novější verze již byly smazány',
  SOLUTION: 'Řešení',
  SOLVE: 'Vyřešit',
  SORT: 'Uspořádat',
  SPECIFIC_SETS_OF_DUBLIN_CORE: 'Specifické sady Dublin Core',
  SPECIFIED_WETHER_THE_ERROR_CAN_BE_RESOLVED_BY_CHANGING_THE_CONFIGURATION:
    'Určuje, zda je možné chybu vyřešit změnou configu.',
  SPECIFIER: 'Specifikátor',
  STACK_TRACE: 'Trasování zásobníku',
  START: 'Spustit',
  STARTWITH: 'Začíná s',
  STATE: 'Stav',
  STATE_OF_AIP: 'Stav AIP',
  STORAGE: 'Uložiště',
  STORAGE_DELETE: 'Smazat uložiště',
  STORAGE_DELETE_TEXT: 'Opravdu chcete smazat uložiště',
  STORAGE_CHECKSUM: 'Kontrolní součet na uložišti',
  STORAGE_IS_AVAILABLE: 'uložiště je dostupné',
  STORAGE_IS_UNAVAILABLE: 'uložiště je nedostupné',
  STORAGE_NAME: 'Název uložiště',
  STORAGE_STATE: 'Stav uložiště',
  STORAGE_TYPE: 'Typ uložiště',
  STORNO: 'Zrušit',
  STUCK_AT: 'Uvízlé v',
  SUBJECT: 'Předmět',
  SUBMIT: 'Potvrdit',
  SUCCESSFULLY_PROCESSED: 'Úspěšně zpracováno',
  SUPER_ADMIN: 'Super správce',
  SUSPEND: 'Pozastavit',
  SUSPENDED: 'Pozastaveno',
  SWITCH_MODE: 'Přepnout režim',
  SWITCH_MODE_CONFIRM: 'Opravdu chcete Archival Storage přepnout do režimu',
  SWITCH_TO_AIP_DETAIL: 'Přejít na detail AIP',
  SWITCH_TO_BATCH: 'Přejít na dávku',
  SWITCH_TO_INGEST_WORKFLOW_DETAIL: 'Přejít na detail ingest workflow',
  SYNCHRONIZATION_COMPLETED: 'synchronizace dokončena',
  SYNCHRONIZATION_FAILED: 'synchronizace selhala',
  SYNCHRONIZATION_INFORMATION: 'Informace o synchronizaci',
  SYNCHRONIZATION_IS_IN_PROGRESS: 'probíhá synchronizace',
  SYNCHRONIZATION_STARTED: 'Synchronizace zahájena',
  TEMPLATE: 'Šablona',
  TEST_ENVIRONMENT: 'Testovací prostředí',
  TEST_FAILED: 'Testování selhalo!',
  TEST_NOW: 'Testovat nyní',
  TEST_ON_STORAGE: 'Otestovat na uložišti',
  TEST_SUCCESSFULL: 'Testování bylo úspěšné.',
  TEXT_CONTENT: 'Textový obsah elementu',
  TIMESTAMP: 'Časové razítko',
  THE_MINIMUM_NUMBER_OF_STORAGES_IS_CHECKED_WHEN_THE_APPLICATION_IS_STARTING_AND_DURING_THE_REMOVAL_OF_STORAGE:
    'Minimální počet uložišt je kontrolován při spuštění aplikace a při operaci odebrání uložiště.',
  THE_PACKAGE_WAS_PROCESSED_IN_DEBUG_MODE: 'Balík byl zpracován v režimu ladění.',
  THE_USER_MUST_HAVE_ASSIGNED_A_PRODUCER: 'Uživatel musí mít přiřazeného dodavatele.',
  THREAT_LEVEL: 'Úroveň ohrožení',
  TO: 'do',
  TOOL: 'Nástroj',
  TOOL_DELETE: 'Smazat nástroj',
  TOOL_DELETE_TEXT: 'Opravdu chcete smazat nástroj',
  TOOL_FUNCTION: 'Funkce nástroje',
  TOOL_NAME: 'Název nástroje',
  TOOL_NEW: 'Nový nástroj',
  TOOL_NEW_FAILED: 'Nepodařilo se vytvořit nový nástroj.',
  TOOLS: 'Nástroje',
  TOTAL: 'Celkem',
  TRANSFER_AREA_PATH: 'Cesta do překladiště',
  TYPE: 'Typ',
  UNKNOWN: 'Neznámo',
  UPDATE_FAILED: 'Aktualizace selhala!',
  UPDATE_FORMAT_USING_PRONOM_NOW: 'Aktualizovat formát za použití PRONOM',
  UPDATE_FORMATS_USING_PRONOM_NOW: 'Aktualizovat formáty za použití PRONOM',
  UPDATE_FORMATS_USING_PRONOM_NOW_TOOLTIP:
    'Aktualizuje celou formátovou knihovnu. Operace probíhá asynchronně a může trvat desítky minut. Po dokončení aktualizace je uživatel notifikován emailem.',
  UPDATE_SUCCESSFULL: 'Aktualizace proběhla úspěšně!',
  UPDATE_WITH_LOCAL_DEFINITION: 'Aktualizovat s lokální definicí',
  UPDATED: 'Upraveno',
  UPLOAD_FILE: 'Nahrát soubor',
  UPLOAD_FILES: 'Nahrát soubory',
  UPLOAD_SIP_CONTENT: 'Nahrát obsah SIP',
  UPLOAD_TEMPLATE: 'Nahrát šablonu',
  UPLOAD_WORKFLOW_CONFIGURATION: 'Nahrát konfiguraci workflow',
  UPLOAD_XML: 'Nahrát XML',
  USER: 'Uživatel',
  USER_ID: 'ID uživatele',
  USERS: 'Uživatelé',
  USER_DELETE: 'Smazat uživatele',
  USER_DELETE_TEXT: 'Opravdu chcete smazat uživatele',
  USER_NEW: 'Nový uživatel',
  USER_NEW_FAILED: 'Nepodařilo se vytvořit nového uživatele.',
  USER_ROLE_DELETE: 'Odebrat roli uživatele',
  USER_ROLE_DELETE_FAILED: 'Odebrání role se nezdařilo.',
  USER_ROLE_DELETE_TEXT: 'Opravdu chcete odebrat uživatelovu roli',
  USER_ROLE_NEW: 'Nová role uživatele',
  USER_ROLE_NEW_FAILED: 'Nepodařilo se přidat novou roli uživateli.',
  USERNAME: 'Uživatelské jméno',
  VALIDATION_PROFILE: 'Validační profil',
  VALIDATION_PROFILE_DELETE: 'Smazat validační profil',
  VALIDATION_PROFILE_DELETE_TEXT: 'Opravdu chcete smazat validační profil',
  VALIDATION_PROFILE_EXTERNAL_ID: 'Externí ID validačního profilu',
  VALIDATION_PROFILE_NEW: 'Nový validační profil',
  VALIDATION_PROFILE_NEW_FAILED: 'Nepodařilo se vytvořit nový validační profil.',
  VALIDATION_PROFILES: 'Validační profily',
  VALUE: 'Hodnota',
  VERSION: 'Verze',
  VERSION_OF: 'Verze',
  WITH_DRAWN_DATE: 'Datum odebrání',
  WORKFLOW_CONFIGURATION: 'Konfigurace workflow',
  WORKFLOW_DEFINITION: 'Definice workflow',
  WORKFLOW_DEFINITION_DELETE: 'Smazat definici workflow',
  WORKFLOW_DEFINITION_DELETE_TEXT: 'Opravdu chcete smazat definici workflow',
  WORKFLOW_DEFINITION_EXTERNAL_ID: 'Externí ID definice workflow',
  WORKFLOW_DEFINITION_NEW: 'Nová definice workflow',
  WORKFLOW_DEFINITION_NEW_FAILED: 'Nepodařilo se vytvořit novou definici workflow.',
  WORKFLOW_DEFINITIONS: 'Definice workflow',
  WRITE_ONLY: 'Pouze zápis',
  XML: 'XML',
  XML_CAN_NOT_BE_DOWNLOADED: 'XML nelze stáhnout.',
  XML_DEFINITION: 'XML definice',
  XML_ELEMENT_NAME: 'Název XML elementu',
  XML_ID: 'XML ID',
  XMLS_STATE: 'XMLS stav',
  XML_VERSION: 'Verze XML',
  XML_VERSION_NUMBER: 'Číslo XML verze',
  XPATH_TO_ID: 'XPath 3.1 (bez namespace prefixů) k uzlu s autorským ID v rámci XML souboru',
  XSL_TRANSFORMATION: 'XSL transformace',
  YES: 'Ano',
  YOU_HAVE_NO_ROLE_MESSAGE: 'Nemáte žádnou roli, vyčkejte na přidělení role administrátorem.',
  EXPORT_CSV: 'Exportovat do CSV',
  EXPORT_XLSX: 'Exportovat do XLSX',
  EXPORT_TEMPLATES: 'Exportní šablony',
  EXPORT_TEMPLATE_DELETE: 'Smazat exportní šablonu',
  EXPORT_TEMPLATE_DELETE_TEXT: 'Opravdu chcete smazat vybranou exportní šablonu?',
  REGEX_LIST: 'Redukce exportu dat seznamem regex',
  METADATA_SELECTION: 'Redukce exportu metadat (exportovat pouze vybraná)',
  EXPORT_TEMPLATE_ID: 'ID exportní šablony',
  INCLUDE: 'Zahrnout vybrané',
  EXCLUDE: 'Odstranit vybrané',
  EXPORT_TEMPLATE_NEW: 'Nová exportní šablona',
  NEW_EXPORT: 'Nový export',
  EXPORT_ROUTINE_TIME: 'Čas plánovaného exportu',
  EXPORT_ROUTINE_LOCATION: 'Lokace plánovaného exportu',
  EXPORT_CONFIGURATION: 'Konfigurace exportu',
  EXPORT_TYPE_IMMEDIATE: 'Okamžitý export do workspace',
  EXPORT_TYPE_PLANNED: 'Plánovaný export do workspace',
  EXPORT_SCOPE: 'Rozsah exportu',
  IDENTIFIERS_LIST: 'Seznam identifikátorů',
  METADATA: 'Metadata',
  DATA: 'Data',
  AIP_XML: 'AIP XML',
  DATA_LATEST_AIP_XML: 'Data a poslední AIP XML',
  DATA_ALL_AIP_XML: 'Data a všechna AIP XML',
  GENERATE_INFO_FILE: 'Vygeneruj informační soubor',
  LOAD_TEMPLATE: 'Načíst ze šablony',
  CLEAR_CONFIGURATION_VALUES: 'Vymazat konfigurační hodnoty',
  EXPORT_ROUTINE: 'Plánovaný export',
  EXPORT_ROUTINE_UPDATED: 'Plánovaný export aktualizován',
  EXPORT_STARTED: 'Export na úložiště započal',
  EXPORT_OPERATION_ERROR: 'Export selhal',
  EXPORT_FOLDER: 'Exportní složka',
  EXPORT_FOLDERS: 'Exportní složky',
  EXPORT_RELATIVE_PATH: 'Relativní cesta exportní složky',
  EXPORT_RELATIVE_PATH_TWO_DOTS_ERROR: 'Relativní cesta nemůže v sobě obsahovat ".."',
  USER_HAS_NO_PRODUCER_ASSIGNED: '- tento uživatel nemá přiřazeného žádného dodavatele',
  USER_PRODUCER_NO_EXPORT_FOLDERS_ASSIGNED: `- dodavatel tohoto uživatele nemá přiřazeny žádné exportní složky`,
  USERS_PRODUCER_EXPORT_FOLDERS: `Exportní složky dodavatele`,
  USERS_EXPORT_FOLDERS: `Exportní složky uživatele`,
  EXPORT_FOLDER_SLASH_ERROR: 'Relativní cesta by neměla začínat s "/"',
  USER_EXPORT_FOLDER_MATCH_ERROR: `Exportní složka uživatele musí být podsložkou jedné z exportních složek dodavatele`,
  IN: 'V',
  TYPE_AND_LIST_OF_IDENTIFIERS: 'Typ a seznam identifikátorů',
  UPLOAD_LIST_OF_IDENTIFIERS: 'Nahrát identifikátory',
  CLEAR_LIST_OF_IDENTIFIERS: 'Odebrat identifikátory',
  FILE_SIZE_LIMIT_EXCEEDED: 'Překročen limit velikosti souboru',
  FILE_SIZE_LIMIT_EXCEEDED_MESSAGE:
    'Soubor, který jste nahráli, překračuje povolený limit velikosti souboru (1 MB).',
  EXPORT_TEMPLATE_NEW_FAILED: 'Nepodařilo se vytvořit novou exportní šablonu',
  CLEAR_EXPORT_TEMPLATE: 'Vymazat šablonu exportu',
  POSTPONE_SELECTED: 'Odložit vybrané',
  PILE: 'Hromádka',
  MY_PILE: 'Moje hromádka',
  EXPORT: 'Exportovat',
  REMOVE_FROM_PILE: 'Odstranit vybrané z hromádky',
  FAILED_TO_ADD_RECORD_TO_PILE: 'Přidání záznamů do hromádky selhalo.',
  SUCCESS_TO_ADD_RECORD_TO_PILE: 'Přidání záznamů do hromádky bylo úspěšné.',
  OPERATION_FAILED: 'Operace selhala!',
  OPERATION_SUCCESS: 'Operace byla úspěšná.',
  FAILED_TO_REMOVE_RECORD_FROM_PILE: 'Odstranění vybraných záznamů z hromádky selhalo.',
  SUCCESS_TO_REMOVE_RECORD_FROM_PILE: 'Odstranění vybraných záznamů z hromádky bylo úspěšné.',
  EMPTY_PILE_MESSAGE: 'Vaše hromádka je momentálně prázdná.',
  DESCRIPTIVE_METADATA_SEARCH: 'Vyhledávání v popisných metadatech',
  AT_LEAST_ONE_EXPORT_SCOPE_CHECKBOX_ERROR:
    'Alespoň jeden checkbox z rozsahu exportu musí být zakliknut.',

  DC_LABEL_ID: 'Specifikátor',
  DC_LABEL_VALUE: 'Fulltext',
  DC_LABEL_TITLE: 'Název',
  DC_LABEL_CREATOR: 'Tvůrce',
  DC_LABEL_SUBJECT: 'Předmět a klíčová slova',
  DC_LABEL_DESCRIPTION: 'Popis',
  DC_LABEL_PUBLISHER: 'Vydavatel',
  DC_LABEL_CONTRIBUTOR: 'Přispěvatel',
  DC_LABEL_DATE: 'Datum',
  DC_LABEL_TYPE: 'Typ zdroje',
  DC_LABEL_FORMAT: 'Formát',
  DC_LABEL_IDENTIFIER: 'Identifikátor zdroje',
  DC_LABEL_SOURCE: 'Zdroj',
  DC_LABEL_LANGUAGE: 'Jazyk',
  DC_LABEL_RELATION: 'Vztah',
  DC_LABEL_COVERAGE: 'Pokrytí',
  DC_LABEL_RIGHTS: 'Správa autorských práv',
};
