import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';

import PageWrapper from '../../components/PageWrapper';
import Detail from '../../components/reingest/Detail';
import { getCurrentReingest } from '../../actions/reingestActions';

const Reingest = ({ history, texts, reingestEntity, ...props }) => (
  <PageWrapper
    {...{
      breadcrumb: [{ label: texts.REINGEST }],
    }}
  >
    <Detail
      {...{
        history,
        texts,
        reingestEntity,
        initialValues: reingestEntity,
        ...props,
      }}
    />
  </PageWrapper>
);

export default compose(
  withRouter,
  connect(
    ({ reingestEntity: { reingestEntity } }) => ({
      reingestEntity,
    }),
    { getCurrentReingest }
  ),
  lifecycle({
    componentWillMount() {
      const { getCurrentReingest } = this.props;

      getCurrentReingest();
    },
  })
)(Reingest);
