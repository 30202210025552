import fetch from '../utils/fetch';
import { showLoader, openErrorDialogIfRequestFailed } from './appActions';

export const runIngestRoutine = (id) => async (dispatch) => {
  dispatch(showLoader());
  try {
      const response = await fetch(`/api/ingest_routine/${id}/run`, {
      method: 'POST',
    });

    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(response));
    return response.status === 200;
  } catch (error) {
    console.log(error);
    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(error));
    return false;
  }
};