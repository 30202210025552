import { CZ, EN, languages } from './languages';

export const reingestState = {
  INITIATED: 'INITIATED',
  INIT_FAILED: 'INIT_FAILED',
  JOB_STOPPED: 'JOB_STOPPED',
  JOB_RUNNING: 'JOB_RUNNING',
  JOB_FINISHED: 'JOB_FINISHED',
};

export const reingestStateTextsCZ = {
  [reingestState.INITIATED]: CZ.REINGEST_STATE_INITIATED,
  [reingestState.INIT_FAILED]: CZ.REINGEST_STATE_INIT_FAILED,
  [reingestState.JOB_STOPPED]: CZ.REINGEST_STATE_JOB_STOPPED,
  [reingestState.JOB_RUNNING]: CZ.REINGEST_STATE_JOB_RUNNING,
  [reingestState.JOB_FINISHED]: CZ.REINGEST_STATE_JOB_FINISHED,
};

export const reingestStateTextsEN = {
  [reingestState.INITIATED]: EN.REINGEST_STATE_INITIATED,
  [reingestState.INIT_FAILED]: EN.REINGEST_STATE_INIT_FAILED,
  [reingestState.JOB_STOPPED]: EN.REINGEST_STATE_JOB_STOPPED,
  [reingestState.JOB_RUNNING]: EN.REINGEST_STATE_JOB_RUNNING,
  [reingestState.JOB_FINISHED]: EN.REINGEST_STATE_JOB_FINISHED,
};

export const reingestStateTexts = {
  [languages.CZ]: reingestStateTextsCZ,
  [languages.EN]: reingestStateTextsEN,
};