export const EN = {
  _1_CONFIRMATION_REQUIRED: '2 confirmations required',
  _2_CONFIRMATIONS_REQUIRED: '1 confirmation required',
  ACKNOWLEDGE_DELETION_REQUEST: 'Acknowledge deletion',
  ACKNOWLEDGE_DELETION_REQUEST_FAILED: 'Deletion acknowledgment failed.',
  ACKNOWLEDGE_DELETION_REQUEST_TEXT: 'Are you sure you want to acknowledge deletion request',
  ACTION_FAILED: 'Action failed!',
  ACTIVE: 'Active',
  ACTIVITY: 'Activity',
  ADMIN: 'Admin',
  ADMINISTRATION: 'Administration',
  AGENT_IDENTIFIER: 'Agent identifier',
  AGENT_IDENTIFIER_TYPE: 'Agent identifier type',
  AGENT_NAME: 'Agent name',
  AGGREGATED_EXTRACTED_TECHNICAL_METADATA: 'Aggregated extracted technical metadata',
  AGGREGATED_TECHNICAL_METADATA_GENERATED_BY_ARCLIB:
    'Aggregated technical metadata generated by Arclib',
  AIP: 'AIP',
  AIP_CAN_NOT_BE_DOWNLOADED: 'AIP can not be downloaded.',
  AIP_DELETE: 'Delete AIP',
  AIP_DELETE_TEXT: 'Are you sure you want to delete AIP',
  AIP_DELETE_TOOLTIP:
    'Creates a deletion request that has to be confirmed by two other users. Deleted package is permanently deleted from the storage.',
  AIP_EDITOR: 'AIP editation',
  AIP_FORGET: 'Forget AIP',
  AIP_ID: 'AIP ID',
  AIP_IDS: 'AIP identifiers',
  AIP_REGISTER_UPDATE_FAILED: 'Editation lock failed!',
  AIP_REMOVE: 'Remove AIP',
  AIP_REMOVE_TEXT: 'Are you sure you want to remove AIP',
  AIP_REMOVE_TOOLTIP:
    'Performs a logical removal of a package. A logically removed package is accessible only to admins. Admins can renew it again.',
  AIP_RENEW: 'Renew AIP',
  AIP_RENEW_TEXT: 'Are you sure you want to renew AIP',
  AIP_SEARCH: 'AIP search',
  AIP_STATE: 'AIP State',
  AIP_STATE_IN_DATABASE: 'AIP state in database',
  ALIASES: 'Aliases',
  ALL: 'All',
  ALL_DEBUG_VERSIONS_OF_THIS_AUTHORIAL_PACKAGE_WILL_BE_DELETED:
    'All debug versions of this authorial package will be deleted.',
  ANALYST: 'Analyst',
  ANY_ATTRIBUTE_NAME: 'Name of any attribute',
  ANY_ATTRIBUTE_VALUE: 'Value of any attribute',
  ARC_EVENT_AGENT_ID: 'ARC event agent ID',
  ARC_EVENT_COUNT: 'ARC event count',
  ARC_EVENT_DATE: 'ARC event date',
  ARC_EVENT_TYPE: 'ARC event type',
  ARCLIB_XML_DS: 'ARCLib XML index report',
  ARCLIB_XML_DS_TOOLTIP:
    'The report is based on the ARCLib XML index - the data source is the index. Otherwise, the data source is the ARCLib database.',
  ARCHIVAL_STORAGE: 'Archival storage',
  ARCHIVAL_STORAGE_ADMINISTRATION: 'Archival storage administration',
  ARCHIVAL_STORAGE_IS_READ_ONLY_AT_THE_MOMENT: 'Archival storage is read only at the moment.',
  ARCHIVIST: 'Archivist',
  ASSIGNEE: 'Assignee',
  ATTACH_AGAIN: 'Attach',
  ATTACH_NEW_STORAGE: 'Attach new storage',
  ATTACH_NEW_STORAGE_FAILED: 'Attachment of new storage failed.',
  AUTHORIAL_ID: 'Authorial ID',
  AUTO: 'Auto',
  AVAILABILITY_OF_STORAGES_LAST_CHECKED: 'Availability of repositories last checked:',
  BATCH: 'Batch',
  BATCH_FAILED_TO_RESUME: 'Batch could not be resumed because of ongoing ingest workflows.',
  BATCH_HAS_SUCCESSFULLY_RESUMED: 'Batch has successfully resumed.',
  BATCH_PROCESS_ONE_LOADER_TEXT: 'Wait for completion of SIP package uploading.',
  BPM_TASK_ID: 'BPM task ID',
  BPMN_DEFINITION: 'BPMN definition',
  AIP_BULK_DELETIONS: 'Bulk deletion',
  AIP_BULK_DELETION_NEW: 'New bulk deletion',
  AIP_BULK_DELETION_NEW_NOTE: 'Identifiers in list must be separated by commas.',
  BYTE_ARRAY: 'Byte array',
  CANCEL: 'Cancel',
  CANCELED: 'Canceled',
  CHECK_NOW: 'Check now',
  CHECKSUM_TYPE: 'Checksum type',
  CLEAN_STORAGE: 'Cleanup',
  CLEAN_STORAGE_ALL: 'Cleanup - all',
  CLEAN_STORAGE_ALL_TEXT: 'Are you sure you want to clean whole storage?',
  CLEAN_STORAGE_FAILED: 'Cleanup failed!',
  CLEAN_STORAGE_SUCCESSFULL: 'Cleanup successfull!',
  CLEAN_STORAGE_TEXT: 'Are you sure you want to clean storage?',
  CLOSE: 'Close',
  CODE: 'Code',
  COLLAPSE_ALL: 'Collapse All',
  COLUMN_SETTINGS: 'Column settings',
  COMPUTED_WORKFLOW_CONFIGURATION: 'Computed workflow configuration',
  CONFIGURATION_FILE: 'Configuration file',
  CONFIGURATION_FILE_LOCAL: 'Local configuration file',
  CONFIRMED: 'Confirmed',
  CONSISTENCY: 'Consistency',
  CONSISTENCY_OF_SIP: 'Consistency of SIP',
  CONTAINS: 'Contains',
  CONTENT_CONSISTENCY: 'Content consistency',
  CREATED: 'Created',
  CREATING_APPLICATION_NAME: 'Creating application name',
  CREATING_APPLICATION_VERSION: 'Creating application version',
  CREATING_APPLICATIONS: 'Creating applications',
  CREATOR: 'Creator',
  CRON_EXPRESSION: 'CRON expression',
  DATABASE_CHECKSUM: 'Database checksum',
  DATE_CREATED: 'Date created',
  DATE_CREATED_BY_APPLICATION: 'Date created by application',
  DATE_TIME_PICKER_PLACEHOLDER: 'DD.MM.YYYY HH:mm',
  DEBUGGING_MODE_ACTIVE: 'Debugging mode active',
  DELETE: 'Delete',
  DELETED_ITEM: 'Deleted item',
  DELETION_ACKNOWLEDGE: 'Deletion acknowledge',
  DELETE_FAILED: 'Deletion failed.',
  DELETED: 'Deleted',
  DELETION_REQUEST_ALREADY_CREATED: 'You have already created deletion request for the AIP.',
  DELETION_REQUEST_SUCCESSFULLY_CREATED: 'Deletion request successfully created.',
  DELETION_REQUESTS: 'Deletion requests',
  DESCRIPTION: 'Description',
  DESCRIPTIVE_METADATA: 'Descriptive metadata',
  DETACH: 'Detach',
  DETACHED: 'Detached',
  DETACHED_BY_ADMIN: 'Detached by admin',
  DETACHED_BY_ERROR: 'Detached by error',
  DEVELOPER_ID: 'Developer ID',
  DEVELOPER_NAME: 'Name',
  DEVELOPER_COUMPOUND_NAME: 'Coumpound name',
  DEVELOPERS: 'Developers',
  DEVICE_FILE_COUNT: 'Device file count',
  DISACKNOWLEDGE_DELETION_REQUEST: 'Refuse deletion',
  DISACKNOWLEDGE_DELETION_REQUEST_FAILED: 'Deletion refusal failed.',
  DISACKNOWLEDGE_DELETION_REQUEST_TEXT: 'Are you sure you want to refuse deletion request',
  DOCUMENTATION: 'Documentation',
  DONE: 'Done',
  DOWNLOAD: 'Download',
  DOWNLOAD_AIP: 'Download AIP',
  DOWNLOAD_XML: 'Download XML',
  DROP_FILE_OR_CLICK_TO_SELECT_FILE: 'Drop file or click to select file to upload.',
  DROP_FILES_OR_CLICK_TO_SELECT_FILES: 'Drop files or click to select files to upload.',
  DUBLIN_CORE: 'Dublin core',
  EDIT: 'Edit',
  EDITABLE: 'Editable',
  EDITED: 'Edited',
  EDITATION: 'Editation',
  EDITOR: 'Editor',
  EMAIL: 'Email',
  ENDWITH: 'Ends with',
  ENTER_CURRENT_OR_FUTURE_DATE: 'Enter current or future date.',
  ENTER_LOCALHOST_OR_IPv4: 'Enter `localhost` or a valid IPv4 address.',
  ENTER_VALID_CRON_FORMAT: 'Enter string in a valid CRON format.',
  ENTER_VALID_DATE_TIME_FORMAT: 'Enter a valid date and time format.',
  ENTER_VALID_EMAIL_ADDRESS: 'Enter a valid email address.',
  ENTER_VALID_JSON: 'Enter a valid JSON.',
  ENTER_VALID_NUMBER: 'Enter a valid numeric value.',
  ENTER_VALID_NUMBER_BIGGER_OR_EQUAL_1: 'Enter a valid numeric value greater than or equal to 1.',
  ENTITY_WITH_THIS_NAME_ALREADY_EXISTS: 'Entity with this name already exists.',
  EQ: 'Equal',
  EVENT: 'Event',
  EVENT_AGENT_ID: 'Event agent ID',
  EVENT_COUNT: 'Event count',
  EVENT_DATE: 'Event date',
  EVENT_DESCRIPTION: 'Event description',
  EVENT_DETAIL: 'Event detail',
  EVENT_ID: 'Event ID',
  EVENT_IDENTIFIER: 'Event identifier',
  EVENT_IDENTIFIER_TYPE: 'Event identifier type',
  EVENT_INGESTION: 'Event ingestion',
  EVENT_OUTCOME: 'Event outcome',
  EVENT_TYPE: 'Event type',
  EVENT_VALIDATION: 'Event validation',
  EVENTS: 'Events',
  EXCEPTION_MESSAGE: 'Error message',
  EXCEPTION_STACKTRACE: 'Exception StackTrace',
  EXPORT_AREA_PATH: 'Export area path',
  EXPORT_CURRENT_PAGE: 'Export current page',
  EXPORT_FAILED: 'Export failed!',
  EXPORT_FORMAT_DEFINITION: 'Export format definition',
  EXPORT_FORMAT_DEFINITIONS: 'Export format definitions',
  EXPORT_JUST_XML: 'Export just XML',
  EXPORT_LOCATION_PATH: 'Export location path',
  EXPORT_ROUTINE_DELETE: 'Delete export routine',
  EXPORT_ROUTINE_DELETE_TEXT: 'Are you sure you want to delete export routine?',
  EXPORT_SEARCH_RESULTS: 'Export search results',
  EXPORT_TIME: 'Export time',
  EXPORT_TYPE: 'Export type',
  EXPORT_WHOLE_AIP_INCLUDING_ALL_XML_VERSIONS:
    'Export whole content of AIP including all XML versions',
  EXPORT_WHOLE_AIP_INCLUDING_LATEST_XML_VERSION:
    'Export whole content of AIP including latest XML version',
  EXTERNAL_ID: 'External ID',
  FAILED: 'Failed',
  FAILED_AND_ALL_CURRENTLY_PROCESSING_STUCKED_OBJECTS_WILL_BE_CLEANED:
    'Failed AND all currently processing/stucked objects and Archival Storage workspace folder will be cleaned.',
  FAILED_TO_READ_FILE: 'Failed to read file.',
  FILE_COUNT: 'File count',
  FILE_FORMAT: 'File format',
  FILE_SUCCESSFULLY_UPLOADED: 'File successfully uploaded.',
  FILES: 'Files',
  FILTER_BY_USER: 'Filter by user',
  FIRST_NAME: 'First name',
  FORGET: 'Forget',
  FORMAT: 'Format',
  FORMAT_CLASSIFICATIONS: 'Format classifications',
  FORMAT_DEFINITION: 'Format definition',
  FORMAT_DESCRIPTION: 'Format description',
  FORMAT_FAMILIES: 'Format families',
  FORMAT_ID: 'Format ID',
  FORMAT_LIBRARY_UPDATE_FAILED: 'Format library update failed.',
  FORMAT_LIBRARY_UPDATE_STARTED: 'Format library update started.',
  FORMAT_NAME: 'Format name',
  FORMAT_NOTE: 'Format note',
  FORMAT_OCCURRENCES: 'Format occurrences',
  FORMAT_REGISTRY_KEY: 'Format registry key',
  FORMAT_REGISTRY_NAME: 'Format registry name',
  FORMAT_RELATION_TYPE: 'Format relation type',
  FORMAT_REVISION: 'Format revision',
  FORMAT_VERSION: 'Format version',
  FORMATS: 'Formats',
  FROM: 'from',
  FULL_NAME: 'Full name',
  GENERAL_CONTENT_SEARCH: 'General search in whole XML',
  GENERIC_DUBLIN_CORE: 'Generic Dublin Core',
  GT: 'Greater',
  GTE: 'Greater or equal',
  HASH_TYPE: 'Hash type',
  HASH_VALUE: 'Hash value',
  HEADER: 'Header',
  HIGHER_PRIORITY_STORAGE_IS_PREFERRED_DURING_READ_OPERATION:
    'Higher priority storage is preferred during read operation.',
  HOME_PAGE: 'Home page',
  HOST: 'Host',
  ID: 'ID',
  ID_OF_PREVIOUS_SIP_VERSION: 'ID of previous SIP version',
  ID_OF_PREVIOUS_XML_VERSION: 'ID of previous XML version',
  IDENTIFICATION_TOOL: 'Identification tool',
  IDENTIFIED_FORMATS: 'Identified formats',
  IDENTIFIER: 'Identifier',
  IDENTIFIER_DELETE: 'Delete identifier',
  IDENTIFIER_DELETE_TEXT: 'Are you sure you want to delete identifier',
  IDENTIFIER_NEW: 'New identifier',
  IDENTIFIER_TYPE: 'Identifier type',
  IDENTIFIERS: 'Identifiers',
  IMAGE_CAPTURE_METADATA: 'Image capture metadata',
  IMAGE_PRODUCER: 'Image producer',
  IMPORT_FORMAT_DEFINITION: 'Import format definition',
  IMPORT_FORMAT_DEFINITIONS: 'Import format definitions',
  IMPORT_FORMAT_DEFINITION_FROM_BYTE_ARRAY: 'Import format definition from byte array file',
  IMPORT_FORMAT_DEFINITIONS_FROM_BYTE_ARRAY: 'Import format definitions from byte array file',
  IMPORT_FORMAT_DEFINITION_FROM_JSON: 'Import format definition from JSON file',
  IMPORT_FORMAT_DEFINITIONS_FROM_JSON: 'Import format definitions from JSON file',
  IN_ADDITION_THE_AVAILABILITY_IS_CHECKED_WITH_EVERY_WRITE:
    'In addition, the availability is checked with every write.',
  INCIDENT: 'Incident',
  INCIDENT_DETAIL: 'Incident detail',
  INCIDENTS: 'Incidents',
  INCIDENTS_CANCEL_FAILED: 'Unable to cancel selected incidents.',
  INCIDENTS_SOLVE_FAILED: 'Unable to solve selected incidents.',
  INCORRECT_USERNAME_OR_PASSWORD: 'Incorrect username or password.',
  INDEX: 'Index',
  INFORMATION_UPDATED: 'Information updated',
  INGEST: 'Ingest',
  INGEST_BATCH: 'Ingest batch',
  INGEST_BATCHES: 'Ingest batches',
  INGEST_ROUTINE: 'Ingest routine',
  INGEST_ROUTINE_DELETE: 'Delete ingest routine',
  INGEST_ROUTINE_DELETE_TEXT: 'Are you sure you want to delete ingest routine',
  INGEST_ROUTINE_NEW: 'New ingest routine',
  INGEST_ROUTINE_NEW_FAILED: 'Failed to create a new ingest routine.',
  INGEST_ROUTINE_NEW_TRANSFER_AREA_PATH_NOTE:
    'Transfer area path denotes the custom path in within the transfer area of the producer, if empty, the root folder of producers transfer area is used.',
  INGEST_ROUTINES: 'Ingest routines',
  INGEST_WORKFLOW: 'Ingest workflow',
  INGEST_WORKFLOW_FAILURE_TYPE: 'Ingest workflow failure type',
  INGEST_WORKFLOW_ID: 'Ingest workflow ID',
  INGEST_WORKFLOWS: 'Ingest workflows',
  INGESTION_EVENT: 'Ingestion event',
  INITIAL_WORKFLOW_CONFIGURATION: 'Initial workflow configuration',
  INSTITUTION: 'Institution',
  INTERNAL: 'Internal',
  INTERNAL_INFORMATION_FILLED: 'Internal information filled',
  INTERNAL_VERSION_NUMBER: 'Internal version number',
  ISSUE: 'Issue',
  ISSUE_DEFINITIONS: 'Issue definitions',
  ISSUE_DELETE: 'Delete issue',
  ISSUE_DELETE_TEXT: 'Are you sure you want to delete issue',
  ISSUE_DICTIONARY: 'Issue dictionary',
  ISSUE_NEW: 'New issue',
  ISSUE_NEW_FAILED: 'Failed to create a new issue.',
  ISSUE_TYPE: 'Issue type',
  ISSUES: 'Issues',
  ISSUES_NOT_AVAILABLE: 'No issues available.',
  JOB: 'Job',
  JSON: 'JSON',
  KEY: 'Key',
  LABEL: 'Label',
  LAST_NAME: 'Last name',
  LATEST_VERSION: 'Latest version',
  LATEST_DATA_VERSION: 'Latest data version',
  LDAPDN: 'LdapDN',
  LICENSE_INFORMATION: 'License information',
  LOADING: 'Loading...',
  LOCAL_DEFINITION: 'Local definition',
  LOCKED_AT: 'Locked at',
  LOCKED_BY_USER: 'Locked by user',
  LOGICAL_STORAGE_ADMINISTRATION: 'Logical storage administration',
  LT: 'Less',
  LTE: 'Less or equal',
  MESSAGE: 'Message',
  METADATA_CONSISTENCY: 'Metadata consistency',
  MIN_STORAGE_COUNT: 'Minimal storage count',
  MODE: 'Mode',
  NAME: 'Name',
  NATIONAL_FORMAT_GUARANTOR: 'National format guarantor',
  NEW: 'New',
  NEW_FAILED: 'Failed to create new item.',
  NEQ: 'Not equal',
  NEW_SEARCH_BASED_ON_QUERY: 'New search based on query',
  NO: 'No',
  NO_ITEMS: 'No items',
  NOTE: 'Note',
  NOTIFICATION: 'Notification',
  NOTIFICATION_DELETE: 'Delete notification',
  NOTIFICATION_DELETE_TEXT: 'Are you sure you want to delete notification?',
  NOTIFICATION_NEW: 'New notification',
  NOTIFICATION_NEW_FAILED: 'Failed to create a new notification.',
  NOTIFICATIONS: 'Notifications',
  NUMBER: 'Number',
  OCCURRENCES: 'Occurrences',
  OK: 'OK',
  ON_STORAGE: 'on storage',
  ONLY_FAILED_OBJECTS_WILL_BE_CLEANED: 'Only failed objects will be cleaned.',
  OPENS_PAGE_WITH_CRON_EXPRESSION_INFORMATION: 'Opens page with CRON expression information.',
  OPENS_PAGE_WITH_GLOB_PATTERN_INFORMATION: 'Opens page with glob pattern information.',
  OPERATION: 'Operation',
  ORGANISATION_NAME: 'Organisation name',
  PAGINATION_COUNT_DIVIDER: '/',
  PARAMS: 'Params',
  PASSWORD: 'Password',
  PASSWORD_NEW: 'New password',
  PATH_TO_XML: 'Path to XML file with authorial ID in the form of a regular expression (regex)',
  PENDING_INCIDENTS: 'Pending incidents',
  PERMISSION: 'Permission',
  PERMISSIONS: 'Permissions',
  PERSISTED: 'Persisted',
  PLANNED_NOTIFICATIONS_TO_ADMINISTRATORS_ABOUT_FORMAT_POLITICS_REVISIONS:
    'Planned notifications to administrators about format politics revisions',
  PORT: 'Port',
  PREFERRED: 'Preferred',
  PRESERVATION_LEVEL_VALUE: 'Preservation level value',
  PRESERVATION_PLAN_DESCRIPTION: 'Preservation plan description',
  PRESERVATION_PLAN_FILE: 'Preservation plan file',
  PRESERVATION_PLANNING: 'Preservation Planning',
  RESET_TABLE: 'Reset table',
  PRIORITY: 'Priority',
  PRIMARY_STORAGE: 'Primary storage',
  PRIMARY_STORAGE_SWITCH: 'Switch primary storage',
  PROCESS_VARIABLE: 'Process variable',
  PROCESS_VARIABLES: 'Process variables',
  PROCESSED: 'Processed',
  PROCESSED_WITH_FAILURES: 'Processed with failures',
  PROCESSING: 'Processing',
  PROCESSING_STATE: 'Processing state',
  PRODUCER: 'Producer',
  PRODUCER_ID: 'Producer ID',
  PRODUCER_NAME: 'Producer name',
  PRODUCERS: 'Producers',
  PRODUCER_DELETE: 'Delete producer',
  PRODUCER_DELETE_TEXT: 'Are you sure you want to delete producer',
  PRODUCER_NEW: 'New producer',
  PRODUCER_NEW_FAILED: 'Failed to create a new producer.',
  PRODUCER_PROFILE: 'Producer profile',
  PRODUCER_PROFILE_DELETE: 'Delete producer profile',
  PRODUCER_PROFILE_DELETE_TEXT: 'Are you sure you want to delete producer profile',
  PRODUCER_PROFILE_EXTERNAL_ID: 'Producer profile external ID',
  PRODUCER_PROFILE_ID_AUTHORIAL_ID: 'Producer profile ID - Authorial ID',
  PRODUCER_PROFILE_HAS_DEBUG_MODE_ACTIVE:
    'Producer profile has debug mode active. In this mode, data will be stored on the disk of the ARCLib server instead of Archival Storage.',
  PRODUCER_PROFILE_NAME: 'Producer profile name',
  PRODUCER_PROFILE_NEW: 'New producer profile',
  PRODUCER_PROFILE_NEW_FAILED: 'Failed to create a new producer profile.',
  PRODUCER_PROFILES: 'Producer profiles',
  PROFILE: 'Profile',
  PROFILE_UPDATED: 'Profile updated successfully.',
  PROVENANCE_AND_CHECKS_DURING_INGEST: 'Provenance and checks during ingest',
  PROVENANCE_DURING_REVALIDATION_WITH_NEW_PROFILE_OR_REPEATED_IDENTIFICATION_OF_FORMATS_IN_SIP:
    'Provenance during revalidation with new profile or repeated identification of formats in SIP',
  PROVENANCE_DURING_UPDATE_OF_AIP_METADATA: 'Provenance during update of AIP metadata',
  PROVENANCE_EXTRACTED_FROM_SIP: 'Provenance extracted from SIP',
  PUID: 'PUID',
  QUERY_NAME: 'Query name',
  R_W: 'R/W',
  READ_ONLY: 'Read only',
  REACHABLE: 'Reachable',
  REACHABILITIY_CHECK_INTERVAL_MINUTES: 'Reachability check interval (minutes)',
  REACHABILITY_OF_STORAGE: 'Reachability of storage',
  REASON: 'Reason',
  RECONFIGURABLE: 'Reconfigurable',
  REJECTED: 'Rejected',
  REINDEX_ARCLIB_XML: 'Reindex ARCLib XML',
  REINDEX_ARCLIB_XML_TEXT:
    'Do you realy want to run reindex for all ARCLib XML stored in the system? The operation is time-consuming - all XMLs are downloaded from Archival Storage.',
  REINDEX_CORE: 'Reindex core',
  REINDEX_FORMAT: 'Reindex format',
  REINDEX_STARTED: 'Reindex started',
  REINGEST: 'Reingest',
  REINGEST_COUNT_TRANSFER_AREA: 'Count transfer area packages',
  REINGEST_DB_STARTED: 'Reingest started at',
  REINGEST_DB_TOTAL: 'Total count of packages',
  REINGEST_DB_EXPORTED: 'Count of already exported packages',
  REINGEST_INITIATE: 'Initiate new Reingest',
  REINGEST_INIT_WARNING: 'Do you really want to run a complete reingest of the archive? This is a very time-consuming operation that fills the transfer area and workspace disks. At the same time, it is not possible to version packages during reingest: ingests that result in versioning are rejected.',
  REINGEST_TERMINATE: 'Terminate',
  REINGEST_TERMINATION_WARNING: 'Do you really want to terminate the reingest? You might want to check the count of packages in transfer area first.',
  REINGEST_STOP_JOB: 'Stop creation of batches',
  REINGEST_RESUME_JOB: 'Continue with creation of batches',
  REINGEST_STATE_INITIATED: 'Initiated',
  REINGEST_STATE_INIT_FAILED: 'Init failed',
  REINGEST_STATE_JOB_STOPPED: 'Creation of batches stopped',
  REINGEST_STATE_JOB_RUNNING: 'Creation of batches started',
  REINGEST_STATE_JOB_FINISHED: 'All batches created',
  RELATED_ENTITIES: 'Related entities',
  RELATED_ERROR: 'Related error',
  RELATED_ERROR_DELETE: 'Delete related error',
  RELATED_ERROR_DELETE_TEXT: 'Are you sure you want to delete related error',
  RELATED_ERROR_NEW: 'New related error',
  RELATED_ERRORS: 'Related errors',
  RELATED_FORMATS: 'Related formats',
  RELATED_ISSUES: 'Related issues',
  RELATED_RISKS: 'Related risks',
  RELATIONSHIP_TYPE: 'Relationship type',
  RELEASE_DATE: 'Release date',
  REMOVE: 'Remove',
  REMOVE_FAILED: 'Removing failed.',
  REMOVE_SUCCESSFULL: 'Removing was successfull.',
  RENEW: 'Renew',
  RENEW_SUCCESSFULL: 'Renewing was successfull.',
  REQUESTED: 'Requested',
  REQUESTER: 'Requester',
  REQUIRED: 'Required',
  REPORT: 'Report',
  REPORT_DELETE: 'Delete report',
  REPORT_DELETE_TEXT: 'Are you sure you want to delete report',
  REPORT_GENERATE: 'Generate report',
  REPORT_GENERATE_FAILED: 'Failed to generate report.',
  REPORT_NEW: 'New report',
  REPORTS: 'Reports',
  RESET: 'Reset',
  RESPONSIBLE_PERSON: 'Responsible person',
  RESPONSIBLE_PERSON_NAME: 'Responsible person',
  RESUME: 'Resume',
  REVERT: 'Revert',
  REVERT_DELETION_REQUEST: 'Revert deletion request',
  REVERT_DELETION_REQUEST_TEXT: 'Are you sure you want to revert deletion request',
  REVERTED: 'Reverted',
  RISK: 'Risk',
  RISK_DELETE: 'Delete risk',
  RISK_DELETE_TEXT: 'Are you sure you want to delete risk',
  RISK_NEW: 'New risk',
  RISK_NEW_FAILED: 'Failed to create a new risk.',
  RISKS: 'Risks',
  RISKS_NOT_AVAILABLE: 'No risks available.',
  ROLE: 'Role',
  ROLE_DELETE: 'Delete role',
  ROLE_DELETE_TEXT: 'Are you sure you want to delete role?',
  ROLE_NEW: 'New role',
  ROLES: 'Roles',
  ROLES_NOT_AVAILABLE: 'No roles available.',
  ROOT: 'Root',
  ROUTINE_AUTO_FLAG_CANT_BE_CHANGED_TOOLTIP: 'Flag is editable only during routine creation.',
  SAVE: 'Save',
  SAVE_AND_CLOSE: 'Save and close',
  SAVE_FAILED: 'Saving failed!',
  SAVE_INTERNAL_INFORMATION: 'Save internal information',
  SAVE_SUCCESSFULL: 'Successfully saved.',
  SAVE_UPDATE_FAILED: 'Unable to save changes.',
  SCANNER_MODEL_SERIAL_NO: 'Scanner model serial no',
  SEARCH_QUERIES: 'Search queries',
  SEARCH_QUERY_DELETE: 'Delete search query',
  SEARCH_QUERY_DELETE_TEXT: 'Are you sure you want to delete search query?',
  SEARCH_QUERY_RESULTS: 'Search query results',
  SEND: 'Send',
  SET_PASSWORD: 'Change/Set password',
  SHOW_SEARCH_RESULTS: 'Show search results',
  SIGN_IN: 'Sign in',
  SIGN_OUT: 'Sign out',
  SIP_CONTENT: 'SIP content',
  SIP_ID: 'SIP ID',
  SIP_CHECKSUM_IN_DATABASE: 'SIP checksum in database',
  SIP_CHECKSUM_ON_STORAGE: 'SIP checksum on storage',
  SIP_METADATA_PATH:
    'Path to main metadata file of SIP (e.g. METS.xml) in the form of a regular expression (regex)',
  SIP_PROCESS_START_FAILED: 'Start of SIP processing failed.',
  SIP_PROCESS_START_SUCCESSFULL: 'Start of SIP processing was successfull.',
  SIP_PROFILE: 'SIP profile',
  SIP_PROFILE_DELETE: 'Delete SIP profile',
  SIP_PROFILE_DELETE_TEXT: 'Are you sure you want to delete SIP profile',
  SIP_PROFILE_EXTERNAL_ID: 'External ID of the SIP profile',
  SIP_PROFILE_NEW: 'New SIP profile',
  SIP_PROFILE_NEW_FAILED: 'Failed to add new SIP profile.',
  SIP_PROFILES: 'SIP profiles',
  SIP_VERSION: 'SIP version',
  SIP_VERSION_NUMBER: 'SIP version number',
  SIZE: 'Size',
  DELETE_IF_NEWER_VERSIONS_DELETED: 'Delete even if newer versions have been deleted',
  SOLUTION: 'Solution',
  SOLVE: 'Solve',
  SORT: 'Sort',
  SPECIFIC_SETS_OF_DUBLIN_CORE: 'Specific sets of Dublin Core',
  SPECIFIED_WETHER_THE_ERROR_CAN_BE_RESOLVED_BY_CHANGING_THE_CONFIGURATION:
    'Specifies whether the error can be resolved by changing the config.',
  SPECIFIER: 'Specifier',
  STACK_TRACE: 'Stack trace',
  START: 'Start',
  STARTWITH: 'Starts with',
  STATE: 'State',
  STATE_OF_AIP: 'State of AIP',
  STORAGE: 'Storage',
  STORAGE_DELETE: 'Delete storage',
  STORAGE_DELETE_TEXT: 'Are you sure you want to delete storage',
  STORAGE_CHECKSUM: 'Storage checksum',
  STORAGE_IS_AVAILABLE: 'storage is available',
  STORAGE_IS_UNAVAILABLE: 'storage is unavailable',
  STORAGE_NAME: 'Storage name',
  STORAGE_STATE: 'Storage state',
  STORAGE_TYPE: 'Storage type',
  STORNO: 'Storno',
  STUCK_AT: 'Stuck at',
  SUBJECT: 'Subject',
  SUBMIT: 'Submit',
  SUCCESSFULLY_PROCESSED: 'Successfully processed',
  SUPER_ADMIN: 'Super admin',
  SUSPEND: 'Suspend',
  SUSPENDED: 'Suspended',
  SWITCH_MODE: 'Switch mode',
  SWITCH_MODE_CONFIRM: 'Do you really mean to switch Archival Storage to mode',
  SWITCH_TO_AIP_DETAIL: 'Switch to AIP detail',
  SWITCH_TO_BATCH: 'Switch to batch',
  SWITCH_TO_INGEST_WORKFLOW_DETAIL: 'Switch to ingest workflow detail',
  SYNCHRONIZATION_COMPLETED: 'synchronization completed',
  SYNCHRONIZATION_FAILED: 'synchronization failed',
  SYNCHRONIZATION_INFORMATION: 'Synchronization information',
  SYNCHRONIZATION_IS_IN_PROGRESS: 'synchronization is in progress',
  SYNCHRONIZATION_STARTED: 'Synchronization started',
  TEMPLATE: 'Template',
  TEST_ENVIRONMENT: 'Test environment',
  TEST_FAILED: 'Test failed!',
  TEST_NOW: 'Test now',
  TEST_ON_STORAGE: 'Test on storage',
  TEST_SUCCESSFULL: 'Test successfull.',
  TEXT_CONTENT: 'Text content of the element',
  TIMESTAMP: 'Timestamp',
  THE_MINIMUM_NUMBER_OF_STORAGES_IS_CHECKED_WHEN_THE_APPLICATION_IS_STARTING_AND_DURING_THE_REMOVAL_OF_STORAGE:
    'The minimum number of storages is checked when the application is starting and during the removal of storage.',
  THE_PACKAGE_WAS_PROCESSED_IN_DEBUG_MODE: 'The package was processed in debug mode.',
  THE_USER_MUST_HAVE_ASSIGNED_A_PRODUCER: 'The user must have assigned a producer.',
  THREAT_LEVEL: 'Threat level',
  TO: 'to',
  TOOL: 'Tool',
  TOOL_DELETE: 'Delete tool',
  TOOL_DELETE_TEXT: 'Are you sure you want to delete tool',
  TOOL_FUNCTION: 'Tool function',
  TOOL_NAME: 'Tool name',
  TOOL_NEW: 'New tool',
  TOOL_NEW_FAILED: 'Failed to create a new tool.',
  TOOLS: 'Tools',
  TOTAL: 'Total',
  TRANSFER_AREA_PATH: 'Transfer area path',
  TYPE: 'Type',
  UNKNOWN: 'Unknown',
  UPDATE_FAILED: 'Update failed!',
  UPDATE_FORMAT_USING_PRONOM_NOW: 'Update format using PRONOM now',
  UPDATE_FORMATS_USING_PRONOM_NOW: 'Update formats using PRONOM now',
  UPDATE_FORMATS_USING_PRONOM_NOW_TOOLTIP:
    'Updates the entire format library. Operation runs asynchronously and can take tens of minutes. Upon completion of the update, the user is notified by email.',
  UPDATE_SUCCESSFULL: 'Update successfull!',
  UPDATE_WITH_LOCAL_DEFINITION: 'Update with local definition',
  UPDATED: 'Updated',
  UPLOAD_FILE: 'Upload file',
  UPLOAD_FILES: 'Upload files',
  UPLOAD_SIP_CONTENT: 'Upload SIP content',
  UPLOAD_TEMPLATE: 'Upload template',
  UPLOAD_WORKFLOW_CONFIGURATION: 'Upload workflow configuration',
  UPLOAD_XML: 'Upload XML',
  USER: 'User',
  USER_ID: 'User ID',
  USERS: 'Users',
  USER_DELETE: 'Delete user',
  USER_DELETE_TEXT: 'Are you sure you want to delete user',
  USER_NEW: 'New user',
  USER_NEW_FAILED: 'Failed to create a new user.',
  USER_ROLE_DELETE: "Delete user's role",
  USER_ROLE_DELETE_FAILED: 'Failed to delete role.',
  USER_ROLE_DELETE_TEXT: "Are you sure you want to delete user's role",
  USER_ROLE_NEW: "New user's role",
  USER_ROLE_NEW_FAILED: 'Failed to add new role to user.',
  USERNAME: 'Username',
  VALIDATION_PROFILE: 'Validation profile',
  VALIDATION_PROFILE_DELETE: 'Delete validation profile',
  VALIDATION_PROFILE_DELETE_TEXT: 'Are you sure you want to delete validation profile',
  VALIDATION_PROFILE_EXTERNAL_ID: 'External ID of the validation profile',
  VALIDATION_PROFILE_NEW: 'New validation profile',
  VALIDATION_PROFILE_NEW_FAILED: 'Failed to add new validation profile.',
  VALIDATION_PROFILES: 'Validation profiles',
  VALUE: 'Value',
  VERSION: 'Version',
  VERSION_OF: 'Version of',
  WITH_DRAWN_DATE: 'Withdrawn date',
  WORKFLOW_CONFIGURATION: 'Workflow configuration',
  WORKFLOW_DEFINITION: 'Workflow definition',
  WORKFLOW_DEFINITION_DELETE: 'Delete workflow definition',
  WORKFLOW_DEFINITION_DELETE_TEXT: 'Are you sure you want to delete workflow definition',
  WORKFLOW_DEFINITION_EXTERNAL_ID: 'External ID of the workflow definition',
  WORKFLOW_DEFINITION_NEW: 'New workflow definition',
  WORKFLOW_DEFINITION_NEW_FAILED: 'Failed to add new workflow definition.',
  WORKFLOW_DEFINITIONS: 'Workflow definitions',
  WRITE_ONLY: 'Write only',
  XML: 'XML',
  XML_CAN_NOT_BE_DOWNLOADED: 'XML can not be downloaded.',
  XML_DEFINITION: 'XML definition',
  XML_ELEMENT: 'XML Element',
  XML_ELEMENT_NAME: 'XML element name',
  XML_ID: 'XML ID',
  XMLS_STATE: 'XMLS state',
  XML_VERSION: 'XML version',
  XML_VERSION_NUMBER: 'XML version number',
  XPATH_TO_ID:
    'XPath 3.1 (without namespace prefixes) to node with authorial ID in within XML file',
  XSL_TRANSFORMATION: 'XSL transformation',
  YES: 'Yes',
  YOU_HAVE_NO_ROLE_MESSAGE:
    'You do not have a role, wait for the administrator to assign the role.',
  EXPORT_CSV: 'Export to CSV',
  EXPORT_XLSX: 'Export to XLSX',
  EXPORT_TEMPLATES: 'Export templates',
  EXPORT_TEMPLATE_DELETE: 'Delete export template',
  EXPORT_TEMPLATE_DELETE_TEXT: 'Are you sure you want to delete this export template?',
  REGEX_LIST: 'Reduction of data export using regex list',
  METADATA_SELECTION: 'Reduction of metadata export (only selected are exported)',
  EXPORT_TEMPLATE_ID: 'Export template ID',
  INCLUDE: 'Include selected',
  EXCLUDE: 'Exclude selected',
  EXPORT_TEMPLATE_NEW: 'New export template',
  NEW_EXPORT: 'New export',
  EXPORT_ROUTINE_TIME: 'Export routine time',
  EXPORT_ROUTINE_LOCATION: 'Export routine location',
  EXPORT_CONFIGURATION: 'Export configuration',
  EXPORT_TYPE_IMMEDIATE: 'Immediate export to workspace',
  EXPORT_TYPE_PLANNED: 'Planned export to workspace',
  EXPORT_SCOPE: 'Export scope',
  IDENTIFIERS_LIST: 'List of identifiers',
  METADATA: 'Metadata',
  DATA: 'Data',
  AIP_XML: 'AIP XML',
  DATA_LATEST_AIP_XML: 'Data and latest AIP XML',
  DATA_ALL_AIP_XML: 'Data and all AIP XML',
  GENERATE_INFO_FILE: 'Generate info file',
  LOAD_TEMPLATE: 'Load from template',
  CLEAR_CONFIGURATION_VALUES: 'Clear configuration values',
  EXPORT_ROUTINE: 'Export routine',
  EXPORT_ROUTINE_UPDATED: 'Export routine successfully updated',
  EXPORT_STARTED: 'Export to storage has successfully started',
  EXPORT_OPERATION_ERROR: 'Export failed',
  EXPORT_FOLDER: 'Export folder',
  EXPORT_FOLDERS: 'Export folders',
  EXPORT_RELATIVE_PATH: 'Relative path of the export folder',
  EXPORT_RELATIVE_PATH_TWO_DOTS_ERROR: 'A relative path cannot contain ".."',
  USER_HAS_NO_PRODUCER_ASSIGNED: '- this user does not have any producer assigned',
  USER_PRODUCER_NO_EXPORT_FOLDERS_ASSIGNED: `- this user's producer does not have any export folders assigned`,
  USERS_PRODUCER_EXPORT_FOLDERS: `User's producer export folders`,
  USERS_EXPORT_FOLDERS: `User's export folders`,
  EXPORT_FOLDER_SLASH_ERROR: 'Relative path should not start with "/"',
  USER_EXPORT_FOLDER_MATCH_ERROR: `The user's export folder must be a subfolder of one of the producer's export folders`,
  IN: 'In',
  TYPE_AND_LIST_OF_IDENTIFIERS: 'Type and list of identifiers',
  UPLOAD_LIST_OF_IDENTIFIERS: 'Upload list of identifiers',
  CLEAR_LIST_OF_IDENTIFIERS: 'Clear list of identifiers',
  FILE_SIZE_LIMIT_EXCEEDED: 'File size limit exceeded',
  FILE_SIZE_LIMIT_EXCEEDED_MESSAGE:
    'The file you have uploaded exceeds the permitted file size limit (1MB).',
  EXPORT_TEMPLATE_NEW_FAILED: 'Failed to create new export template.',
  CLEAR_EXPORT_TEMPLATE: 'Clear export template',
  POSTPONE_SELECTED: 'Add selected to my pile.',
  PILE: 'Pile',
  MY_PILE: 'My pile',
  EXPORT: 'Export',
  REMOVE_FROM_PILE: 'Remove the selected from pile',
  FAILED_TO_ADD_RECORD_TO_PILE: 'Failed to add records to pile.',
  SUCCESS_TO_ADD_RECORD_TO_PILE: 'Adding records to pile was successful.',
  OPERATION_FAILED: 'Operation failed!',
  OPERATION_SUCCESS: 'Operation was successful.',
  FAILED_TO_REMOVE_RECORD_FROM_PILE: 'Failed to remove the selected records from the pile.',
  SUCCESS_TO_REMOVE_RECORD_FROM_PILE:
    'Removal of the selected records from the pile was successful.',
  EMPTY_PILE_MESSAGE: 'Your pile is currently empty.',
  DESCRIPTIVE_METADATA_SEARCH: 'Searching in descriptive metadata',
  AT_LEAST_ONE_EXPORT_SCOPE_CHECKBOX_ERROR:
    'At least one checkbox from the export range must be checked.',

  DC_LABEL_ID: 'Specifier',
  DC_LABEL_VALUE: 'Fulltext',
  DC_LABEL_TITLE: 'Title',
  DC_LABEL_CREATOR: 'Creator',
  DC_LABEL_SUBJECT: 'Subject',
  DC_LABEL_DESCRIPTION: 'Description',
  DC_LABEL_PUBLISHER: 'Publisher',
  DC_LABEL_CONTRIBUTOR: 'Contributor',
  DC_LABEL_DATE: 'Date',
  DC_LABEL_TYPE: 'Type',
  DC_LABEL_FORMAT: 'Format',
  DC_LABEL_IDENTIFIER: 'Identifier',
  DC_LABEL_SOURCE: 'Source',
  DC_LABEL_LANGUAGE: 'Language',
  DC_LABEL_RELATION: 'Relation',
  DC_LABEL_COVERAGE: 'Coverage',
  DC_LABEL_RIGHTS: 'Rights',
};
