import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import { reduxForm, reset, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { get, isEmpty, map } from 'lodash';

import DialogContainer from './DialogContainer';
import { SelectField, Validation } from '../form';
import { Button } from 'react-bootstrap/lib/InputGroup';
import { getArchivalStorageConfig, switchPrimaryStorage } from '../../actions/storageActions';
import { connect } from 'react-redux';

const SwitchPrimaryStorage = ({ handleSubmit, texts, storages, language }) => (
  <DialogContainer
    {...{
      title: texts.PRIMARY_STORAGE_SWITCH,
      name: 'SwitchPrimaryStorage',
      handleSubmit,
      submitLabel: texts.SUBMIT,
    }}
  >
    <form {...{ onSubmit: handleSubmit }}>
      {map(
        [
          {
            component: SelectField,
            label: texts.PRIMARY_STORAGE,
            name: 'selectedStorage',
            validate: [Validation.required[language]],
            options: map(storages, (selectedStorage) => ({
              label: selectedStorage.name,
              value: selectedStorage.id,
            })),
          },
        ],
        ({ buttons, name, ...field }, key) => (
          <div {...{ key }}>
            <Field {...{ id: `producer-profile-new-${name}`, name, ...field }} />
            {!isEmpty(buttons) && (
              <div {...{ className: 'flex-row flex-right' }}>
                {map(buttons, ({ label, ...props }, key) => (
                  <Button {...{ key, ...props }}>{label}</Button>
                ))}
              </div>
            )}
          </div>
        )
      )}
    </form>
  </DialogContainer>
);
export default compose(
  connect(null, {
    switchPrimaryStorage,
    getArchivalStorageConfig,
    reset
  }),
  withRouter,
  withProps(({ data }) => {
    return {
      ...data,
      initialValues: {
        selectedStorage: get(data, 'currentPrimaryStorageId')
      },
    }
  }),
  withHandlers({
    onSubmit: ({ closeDialog, switchPrimaryStorage, getArchivalStorageConfig, reset }) => async (selectedStorage) => {
      const ok = await switchPrimaryStorage(selectedStorage.selectedStorage);
      if (ok) {
        await getArchivalStorageConfig();
      }

      reset('SwitchPrimaryStorageDialogForm');
      closeDialog();
    },
  }),
  reduxForm({
    form: 'SwitchPrimaryStorageDialogForm',
    enableReinitialize: true,
  })
)(SwitchPrimaryStorage);
