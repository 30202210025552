import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, withProps } from 'recompose';
import { get, map } from 'lodash';

import Button from '../Button';
import ConfirmButton from '../ConfirmButton';
import { postArchivalStorageConfig } from '../../actions/storageActions';
import { countReingestPackages, initiateReingest, resumeReingest, stopReingest, terminateReingest } from '../../actions/reingestActions';
import { reingestStateTexts } from '../../enums/reingest';
import { setDialog } from '../../actions/appActions';

const Detail = ({ texts, reingestEntity, setDialog, initiateReingest, stopReingest, countReingestPackages, resumeReingest, terminateReingest }) => (
  <div>
    {!reingestEntity && (
      <div>
        {map(
          [
            {
              label: texts.REINGEST_INITIATE,
              onClick: async () => {
                await initiateReingest();
              },
              title: texts.REINGEST,
              text: texts.REINGEST_INIT_WARNING,
              className: 'margin-right-small',
            },
          ],
          (button, key) => (
            <ConfirmButton {...{ key, ...button }} />
          )
        )}
      </div>
    )}
    {reingestEntity && (
      <div>
        <ul>
          <li>{texts.REINGEST_DB_STARTED}: {reingestEntity.created}</li>
          <li>{texts.REINGEST_DB_TOTAL}: {reingestEntity.size}</li>
          <li>{texts.REINGEST_DB_EXPORTED}: {reingestEntity.nextOffset}</li>
          <li>{texts.STATE}: {reingestEntity.state}</li>
        </ul>

        {reingestEntity.state === 'JOB_STOPPED' && (
          <Button {...{
            className: 'margin-right-small',
            onClick: async () => {
              await resumeReingest();
            },
          }}>
            {texts.REINGEST_RESUME_JOB}
          </Button>
        )}
        {reingestEntity.state === 'JOB_RUNNING' && (
          <Button {...{
            className: 'margin-right-small',
            onClick: async () => {
              await stopReingest();
            },
          }}>
            {texts.REINGEST_STOP_JOB}
          </Button>
        )}
        <Button {...{
          className: 'margin-right-small',
          onClick: async () => {
            const response = await countReingestPackages();
            if (response.status === 200) {
              const count = await response.json();
              console.log(count);
              setDialog('Info', {
                content: (
                  <h4>
                    <strong>{texts.REINGEST_COUNT_TRANSFER_AREA}: {count}</strong>
                  </h4>
                ),
              });
            }
          },
        }}>
          {texts.REINGEST_COUNT_TRANSFER_AREA}
        </Button>
        {map(
          [
            {
              label: texts.REINGEST_TERMINATE,
              onClick: async () => {
                await terminateReingest();
              },
              title: texts.REINGEST_TERMINATE,
              text: texts.REINGEST_TERMINATION_WARNING,
              className: 'margin-top-small',
            },
          ],
          (button, key) => (
            <ConfirmButton {...{ key, ...button }} />
          )
        )}
      </div>
    )}
  </div>
);

export default compose(
  connect(null, {
    postArchivalStorageConfig,
    initiateReingest,
    terminateReingest,
    stopReingest,
    countReingestPackages,
    resumeReingest,
    setDialog
  }),
  withProps(({ reingestEntity, language }) => ({
    initialValues: {
      ...reingestEntity,
      reingestState: get(reingestStateTexts[language], get(reingestEntity, 'state')),
    },
  })),
  reduxForm({
    form: 'reingest-detail',
    enableReinitialize: true,
  })
)(Detail);
