import * as c from './constants';
import fetch from '../utils/fetch';
import { openErrorDialogIfRequestFailed, showLoader } from './appActions';

export const getCurrentReingest = () => async (dispatch) => {
  dispatch(showLoader());

  dispatch({
    type: c.REINGEST,
    payload: {
      reingestEntity: null,
    },
  });

  try {
    const response = await fetch('/api/administration/reingest');

    if (response.status === 200) {
      const reingestEntity = response.headers.get('Content-Length') === '0' ? null : await response.json();

      dispatch({
        type: c.REINGEST,
        payload: {
          reingestEntity,
        },
      });

      dispatch(showLoader(false));
      return reingestEntity;
    }

    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(response));
    return false;
  } catch (error) {
    console.log(error);
    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(error));
    return false;
  }
};

export const initiateReingest = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`/api/administration/reingest/init`, {
      method: 'POST',
    });

    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(response));
    const ok = response.status === 200;
    if (ok) {
      dispatch({
        type: c.REINGEST,
        payload: {
          reingestEntity: await response.json()
        }
      });
    }
    return ok;
  } catch (error) {
    console.log(error);
    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(error));
    return false;
  }
};

export const terminateReingest = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`/api/administration/reingest/terminate`, {
      method: 'POST',
    });

    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(response));
    const ok = response.status === 200;
    if (ok) {
      dispatch({
        type: c.REINGEST,
        payload: {
          reingestEntity: null,
        }
      });
    }
    return ok;
  } catch (error) {
    console.log(error);
    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(error));
    return false;
  }
};

export const stopReingest = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`/api/administration/reingest/stop`, {
      method: 'POST',
    });

    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(response));
    const ok = response.status === 200;
    if (ok) {
      dispatch({
        type: c.REINGEST,
        payload: {
          reingestEntity: await response.json(),
        }
      });
    }
    return ok;
  } catch (error) {
    console.log(error);
    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(error));
    return false;
  }
};

export const countReingestPackages = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`/api/administration/reingest/count`, {
      method: 'GET',
    });

    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(response));
    return response;
  } catch (error) {
    console.log(error);
    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(error));
    return false;
  }
};

export const resumeReingest = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`/api/administration/reingest/resume`, {
      method: 'POST',
    });

    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(response));
    const ok = response.status === 200;
    if (ok) {
      dispatch({
        type: c.REINGEST,
        payload: {
          reingestEntity: await response.json(),
        }
      });
    }
    return ok;
  } catch (error) {
    console.log(error);
    dispatch(showLoader(false));
    dispatch(await openErrorDialogIfRequestFailed(error));
    return false;
  }
};